import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/20/solid";
import Stars from "../rating/Stars";
import useCompanies from "../../hook/companies";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DetailSclice, fetchOneCompany } from "../../redux/details";
import { baseUrl } from "../../utils";
import { setPage } from "../../redux/company";

function Template({ items }) {
  const dispatch = useDispatch();


  return (
    <div className="w-full flex justify-center items-center flex-col  h-80  cursor-pointer p-6 bg-white border border-gray-100 rounded-lg  shadow-stone-200 dark:bg-gray-800 dark:border-gray-700">
      <div className="h-32 flex flex-col items-center">
        {!items.logo && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={0.7}
            stroke="currentColor"
            className="w-12 h-12 text-sky-300"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"
            />
          </svg>
        )}
        {items.logo && (
          <img
            className="w-12 h-12 mb-3 shadow-none object-scale-down"
            src={`${[baseUrl, items?.logo].join("")}`}
            alt={items?.name.toLowerCase().substring(0, 2)}
          />
        )}

        <h5 className="mb-1 text-sm  text-center lg:text-lg font-medium text-indigo-900 dark:text-white">
          {items?.name}
        </h5>
        <Stars number={items?.stars} />
      </div>
      <div className="flex space-x-2">
        <span className="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-bold text-gray-600">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
            />
          </svg>
          +{items?.jobs?.number}
        </span>
        {items?.industry && (
          <span className="inline-flex items-center rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-700">
            {items?.industry[0]}
          </span>
        )}
        {items?.industry?.sector0 && (
          <span className="inline-flex items-center rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-700">
            {items?.industry?.sector0}
          </span>
        )}
      </div>
      <div className="flex flex-row flex-wrap justify-center  mt-5 space-x-3 md:mt-6">
        <Link
          to={`/company/${items?.name}`}
          onClick={() => dispatch(fetchOneCompany(items.id))}
          className="inline-flex text-xs items-center px-4 py-2 lg:text-sm font-medium text-center text-white bg-sky-400 rounded-lg hover:bg-sky-600 focus:ring-4 focus:outline-none focus:ring-sky-300 dark:bg-sky-600 dark:hover:bg-sky-700 dark:focus:ring-sky-800"
        >
          About company
        </Link>
      </div>
    </div>
  );
}

export default function Employers() {
  const { companies, page, limit } = useSelector((state) => state.companySlice);
  const start = (page - 1) * limit + 1;
  const dispatch = useDispatch();
  const end = Math.min(page * limit, companies.total);

  return (
    <section className="bg-white dark:bg-gray-900 inline-block ">
      <div className="hidden md:block space-y-2 lg:py-2 mx-auto max-w-screen-xl md:px-4">
        <div className="grid  grid-cols-1 gap-x-3 gap-y-2 text-gray-500  md:grid-cols-3  dark:text-gray-400    ">
          {companies.datas &&
            companies.datas.map((item, index) => (
              <Template key={index} items={item} />
            ))}

          {!companies.datas && (
            <>
              <TemplateLoading />
              <TemplateLoading />
              <TemplateLoading />
            </>
          )}
        </div>

        <div className="flex flex-row gap-x-3 ">
          <button
            className="btn flex-1"
            disabled={start === 1}
            onClick={() => dispatch(setPage(page - 1))}
          >
            Prev
          </button>
          <button
            className="btn flex-1"
            disabled={end === companies.total}
            onClick={() => dispatch(setPage(page + 1))}
          >
            Next
          </button>
        </div>
      </div>
      <div className="md:hidden block space-y-2 lg:py-2 mx-auto max-w-screen-xl md:px-4">
        <div className="grid  grid-cols-1 gap-x-3 gap-y-2 text-gray-500  md:grid-cols-3  dark:text-gray-400    ">
          {companies.datas &&
            companies.datas.map((item, index) => (
              <Template key={index} items={item} />
            ))}
          {!companies.datas && <TemplateLoading />}
        </div>

        <div className="flex flex-row gap-x-3 ">
          <button
            className="btn flex-1"
            onClick={() => dispatch(setPage(page - 1))}
            disabled={start === 1}
          >
            Prev
          </button>
          <button
            className="btn flex-1"
            disabled={end === companies.total}
            onClick={() => dispatch(setPage(page + 1))}
          >
            Next
          </button>
        </div>
      </div>
    </section>
  );
}

function TemplateLoading() {
  return (
    <div className="w-full  h-80  cursor-pointer  bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
      <div className="flex justify-end px-4 pt-4">
        <button
          id="dropdownButton"
          data-dropdown-toggle="dropdown"
          className="inline-block text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-1.5"
          type="button"
        >
          <span className="sr-only">Open dropdown</span>
          <div className="w-5 h-5 bg-slate-200 animate-pulse"></div>
        </button>
      </div>
      <div className="flex flex-col items-center pb-10">
        <div className="h-32 flex flex-col items-center">
          <div className="w-12 h-12 bg-slate-200 animate-pulse rounded-full"></div>

          <h5 className="mb-1 text-sm   text-center text-transparent lg:text-lg font-bold  dark:text-white w-20  h-4"></h5>
          <div className="rounded-full bg-slate-200 animate-pulse w-20  h-4"></div>
        </div>
        <div className="flex space-x-2">
          <span
            className="inline-flex items-center w-6 h-6
            bg-slate-200 px-2 py-1 text-xs font-bold rounded-full"
          ></span>
        </div>
        <div className="flex flex-row flex-wrap justify-center  mt-5 space-x-3 md:mt-6">
          <Link className="inline-flex text-xs items-center px-4 py-2 lg:text-sm font-medium text-center text-transparent bg-slate-200 rounded-lg hover:bg-sky-600 focus:ring-4 focus:outline-none focus:ring-sky-300 dark:bg-sky-600 dark:hover:bg-sky-700 dark:focus:ring-sky-800">
            About company
          </Link>
        </div>
      </div>
    </div>
  );
}
