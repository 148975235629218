import React, { useRef, useState } from "react";
import { PencilIcon, QuestionMarkCircleIcon } from "@heroicons/react/20/solid";
import { useSelector } from "react-redux";
import { baseUrl } from "../../../utils";
import { useLogo, useUpdateProfile } from "../../../hook/profile";
import useIndustries from "../../../hook/industry";
function Account() {
  const [enableEdit, setEnableEdit] = useState(false);
  const { company, onLoad } = useSelector((state) => state.companySlice);
  const data = JSON.parse(JSON.stringify(company));
  const file = useRef();
  const { onUpdate, isChanged, saveCompany, temp } = useUpdateProfile(
    data,
    data
  );
  const { getFile, theFile, save, reset } = useLogo();
  const { datas } = useIndustries();

  return (
    !onLoad && (
      <div className="divide-y divide-gray-300">
        <div className="grid   overflow-hidden grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-800">
              Personal Information
            </h2>
            <p className="mt-1 md:text-sm text-xs leading-6 text-gray-600">
              Use a permanent address where you can receive mail.
            </p>
          </div>

          <form className="md:col-span-2">
            <div className="grid grid-cols-1 gap-x-6 sm:gap-y-8 gap-y-4 w-sm  sm:grid-cols-6">
              <div className="col-span-full flex items-center gap-x-8">
                {onLoad && (
                  <div className="h-24 w-24 flex-none rounded-lg bg-slate-200 animate-pulse object-cove"></div>
                )}

                {company && (
                  <img
                    src={
                      theFile
                        ? URL.createObjectURL(theFile)
                        : [baseUrl, company.logo].join("")
                    }
                    alt=""
                    className="h-24 w-24 flex-none rounded-lg bg-gray-200 object-cover"
                  />
                )}

                {!theFile && (
                  <div>
                    <button
                      type="button"
                      onClick={() => file.current.click()}
                      className="rounded-md border-sky-400 bg-white px-3 py-2 md:text-sm text-xs font-semibold text-gray-800 shadow-sm hover:bg-gray-100"
                    >
                      Change logo
                    </button>
                    <p className="mt-2 text-xs leading-5 text-gray-600">
                      JPG, GIF or PNG. 1MB max.
                    </p>
                    <input
                      ref={file}
                      type="file"
                      onChange={(e) => {
                        getFile(e.target.files[0]);
                      }}
                      className="hidden"
                      accept=".jpg,.jpeg,.png,.gif"
                      size={2 * 1024}
                      name=""
                      id=""
                    />
                  </div>
                )}

                {theFile && (
                  <div className="flex flex-col space-y-4 ">
                    <h1
                      onClick={() => save(company.id)}
                      className="text-sm md:text-lg font-medium cursor-pointer active:text-sky-700 text-sky-500"
                    >
                      save image
                    </h1>

                    <h1
                      onClick={reset}
                      className="text-sm md:text-lg font-medium cursor-pointer active:text-red-700 text-red-500"
                    >
                      Cancel
                    </h1>
                  </div>
                )}
              </div>

              <div className="sm:col-span-full">
                <label
                  htmlFor="first-name"
                  className="block md:text-sm text-xs font-medium leading-6 text-gray-800"
                >
                  Company name
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="name"
                    id="first-name"
                    readOnly
                    defaultValue={company.name}
                    autoComplete="given-name"
                    className="block pl-3 w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 md:text-sm text-xs sm:leading-6"
                  />
                </div>
              </div>
              <div className="sm:col-span-3">
                <label
                  htmlFor="first-name"
                  className="block md:text-sm text-xs font-medium leading-6 text-gray-800"
                >
                  Company registration number (C.R.N)
                  <span className="text-red-600 after:content-['*']"></span>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="crn"
                    id="first-name"
                    value={temp.crn}
                    onChange={onUpdate}
                    defaultValue={company.crn}
                    autoComplete="given-name"
                    className={`block pl-3 w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ${
                      company.crn ? "ring-gray-300" : "ring-red-500"
                    }  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 md:text-sm text-xs sm:leading-6`}
                  />
                </div>
              </div>
              <div className="sm:col-span-3">
                <label
                  htmlFor="first-name"
                  className="block md:text-sm text-xs font-medium leading-6 text-gray-800"
                >
                  Company VAT
                  <span className="text-red-600 after:content-['*']"></span>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="vat"
                    id="first-name"
                    value={temp.vat}
                    onChange={onUpdate}
                    defaultValue={company.vat}
                    autoComplete="given-name"
                    className={`block pl-3 w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ${
                      company.vat ? "ring-gray-300" : "ring-red-500"
                    }  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 md:text-sm text-xs sm:leading-6`}
                  />
                </div>
              </div>

              <div className="sm:col-span-full">
                <label
                  htmlFor="first-name"
                  className="block md:text-sm text-xs font-medium leading-6 text-gray-800"
                >
                  Owner
                  <span className="text-red-600 after:content-['*']"></span>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="owner"
                    onChange={onUpdate}
                    id="first-name"
                    defaultValue={company.owner}
                    autoComplete="given-name"
                    className="block pl-3 w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 md:text-sm text-xs sm:leading-6"
                  />
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="username"
                  className="block md:text-sm text-xs font-medium leading-6 text-gray-800"
                >
                  Username
                </label>
                <div className="mt-2">
                  <div className="flex rounded-md bg-white border-gray-300 focus-within:ring-2 ring-1 ring-inset ring-gray-300 focus-within:ring-sky-500">
                    <span className="flex select-none items-center pl-3 text-gray-500 md:text-sm text-xs">
                      tempme.co/company/
                    </span>
                    <input
                      type="text"
                      name="username"
                      id="username"
                      readOnly
                      defaultValue={company.name.replaceAll(" ", "-")}
                      autoComplete="username"
                      className="flex-1 border-0 lowercase bg-transparent py-1.5 pl-1 text-gray-800 focus:ring-0 md:text-sm text-xs sm:leading-6"
                      placeholder="janesmith"
                    />
                  </div>
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="email"
                  className="block md:text-sm text-xs font-medium leading-6 text-gray-800"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    defaultValue={company.email}
                    onChange={onUpdate}
                    autoComplete="email"
                    className="block pl-3 w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 md:text-sm text-xs sm:leading-6"
                  />
                </div>
              </div>
              <div className="col-span-full">
                <label
                  htmlFor="email"
                  className="block md:text-sm text-xs font-medium leading-6 text-gray-800"
                >
                  Phone
                  <span className="text-red-600 after:content-['*']"></span>
                </label>
                <div className="mt-2 relative">
                   <div className="absolute inset-y-0 left-0 flex items-center">
                    <label htmlFor="prefix" className="sr-only">
                      Prefix
                    </label>
                    <select
                      id="prefix"
                      name="phone.prefix"
                      onChange={onUpdate}
                      value={temp.phone.prefix}
                      className="h-full rounded-md border-0 bg-transparent py-0 pl-2 pr-7 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-sky-600 md:text-sm text-xs"
                    >
                      <option value={"+44"}>UK (+44)</option>
                      <option value={"+966"}>KSA (+966)</option>
                      <option value={"+971"}>UAE (+971)</option>
                    </select>
                  </div>
                  <input
                    id="phone"
                    name="phone.value"
                    type="tel"
                    onChange={onUpdate}
                    defaultValue={company.phone}
                    autoComplete="phone"
                    className="block pl-32 w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 md:text-sm text-xs sm:leading-6"
                  />
                </div>
              </div>
            </div>

            <div className="mt-8 flex">
              <button
                disabled={isChanged()}
                onClick={saveCompany}
                className="rounded-md disabled:bg-slate-500 disabled:opacity-50 bg-sky-500 px-3 py-2 md:text-sm text-xs font-semibold text-white shadow-sm hover:bg-sky-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500"
              >
                Save
              </button>
            </div>
          </form>
        </div>

        <div className="grid  grid-cols-1 gap-x-8  gap-y-4 md:gap-y-8 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-800">
              Location
            </h2>
            <p className="mt-1 md:text-sm text-xs leading-6 text-gray-600">
              Please enter your home location for better experience
            </p>
          </div>

          <form className="md:col-span-2">
            <div className="grid grid-cols-1 gap-x-6 md:gap-y-8 gap-y-4  sm:grid-cols-6">
              <div className="col-span-full">
                <label
                  for="street"
                  className="block md:text-sm text-xs font-medium leading-6 text-gray-800"
                >
                  Country
                </label>
                <div className="mt-2">
                  <input
                    id="street"
                    type="text"
                    readOnly
                    defaultValue={company.currency.country.name}
                    autocomplete="street"
                    className="block pl-3 w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 md:text-sm text-xs sm:leading-6"
                  />
                </div>
              </div>
              <div className="col-span-3">
                <label
                  for="street"
                  className="block md:text-sm text-xs font-medium leading-6 text-gray-800"
                >
                  Street
                </label>
                <div className="mt-2">
                  <input
                    id="street"
                    name="address.street"
                    type="text"
                    onChange={onUpdate}
                    defaultValue={company.address.street}
                    autocomplete="street"
                    className="block pl-3 w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 md:text-sm text-xs sm:leading-6"
                  />
                </div>
              </div>
              <div className="col-span-3">
                <label
                  for="city"
                  className="block md:text-sm text-xs font-medium leading-6 text-gray-800"
                >
                  City
                </label>
                <div className="mt-2">
                  <input
                    id="city"
                    name="address.city"
                    type="text"
                    onChange={onUpdate}
                    defaultValue={company.address.city}
                    autocomplete="city"
                    className="block pl-3 w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 md:text-sm text-xs sm:leading-6"
                  />
                </div>
              </div>
              <div className="col-span-3">
                <label
                  for="zip"
                  className="block md:text-sm text-xs font-medium leading-6 text-gray-800"
                >
                  Zip/Post code
                </label>
                <div className="mt-2">
                  <input
                    id="logout-password"
                    name="address.zip"
                    type="text"
                    onChange={onUpdate}
                    defaultValue={company.address?.zip}
                    autocomplete="zip"
                    className="block pl-3 w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 md:text-sm text-xs sm:leading-6"
                  />
                </div>
              </div>
              <div className="col-span-3">
                <label
                  for="state"
                  className="block md:text-sm text-xs font-medium leading-6 text-gray-800"
                >
                  State
                </label>
                <div className="mt-2">
                  <input
                    id="state"
                    name="address.state"
                    defaultValue={company.address.state}
                    type="state"
                    onChange={onUpdate}
                    autocomplete="state"
                    className="block pl-3 w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 md:text-sm text-xs sm:leading-6"
                  />
                </div>
              </div>
            </div>

            <div className="mt-8 flex">
              <button
                disabled={isChanged()}
                onClick={saveCompany}
                className="rounded-md disabled:bg-slate-500 disabled:opacity-50  bg-sky-500 px-3 py-2 md:text-sm text-xs font-semibold text-white shadow-sm hover:bg-sky-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500"
              >
                save
              </button>
            </div>
          </form>
        </div>
        <div className="grid  grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-800">
              Company preferences
            </h2>
            <p className="mt-1 md:text-sm text-xs leading-6 text-gray-600">
              Choose your sector and give us little description
            </p>
          </div>

          <form className="md:col-span-2">
            <div className="grid grid-cols-1 gap-x-6 md:gap-y-8 gap-y-4 sm:grid-cols-6">
              <div className="col-span-full">
                <label
                  htmlFor="last-name"
                  className="block md:text-sm text-xs font-medium leading-6 text-gray-800"
                >
                  My industry
                  <span className="text-red-600 after:content-['*']"></span>
                </label>
                <div className="relative mt-2 rounded-md shadow-sm">
                  <input
                    type="text"
                    name="industry"
                    id="industry"
                    onChange={onUpdate}
                    defaultValue={company.industry}
                    className={`block pl-3 w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ${
                      company.industry && company.industry[0]
                        ? "ring-gray-300"
                        : "ring-red-500"
                    }  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 md:text-sm text-xs sm:leading-6`}
                    placeholder="Fashion"
                  />
                  <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                    <PencilIcon
                      className="h-5 w-5 text-gray-400  hover:text-sky-600 cursor-pointer"
                      onClick={() => setEnableEdit(!enableEdit)}
                    />
                  </div>
                </div>
              </div>
              {enableEdit && (
                <div className="col-span-full">
                  <label
                    for="street"
                    className="block md:text-sm text-xs font-medium leading-6 text-gray-800"
                  >
                    Sector
                  </label>
                  <div className="mt-2">
                    <select
                      id="industry"
                      name="industry"
                      onChange={onUpdate}
                      required
                      placeholder="choose sector"
                      className={
                        "mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-sky-600 text-xs md:text-sm sm:leading-6"
                      }
                    >
                      {datas &&
                        datas.map((el) => (
                          <option key={el.name} value={el.name}>
                            {el.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              )}

              <div className="col-span-full">
                <label
                  htmlFor="comment"
                  className="block text-xs md:text-sm font-medium leading-6 text-gray-900"
                >
                  Description
                  <span className="text-red-600 after:content-['*']"></span>
                </label>
                <div className="mt-2">
                  <textarea
                    rows={3}
                    name="description"
                    onChange={onUpdate}
                    required
                    id="comment"
                    className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ${
                      company.description ? "ring-gray-300" : "ring-red-500"
                    }  placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 text-xs md:text-sm sm:leading-6`}
                    defaultValue={company.description}
                  />
                </div>
              </div>
            </div>

            <div className="mt-8 flex">
              <button
                disabled={isChanged()}
                onClick={saveCompany}
                className="rounded-md disabled:bg-slate-500 disabled:opacity-50  bg-sky-500 px-3 py-2 md:text-sm text-xs font-semibold text-white shadow-sm hover:bg-sky-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500"
              >
                save
              </button>
            </div>
          </form>
        </div>

        <div className="grid  grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-800">
              Delete account
            </h2>
            <p className="mt-1 md:text-sm text-xs leading-6 text-gray-600">
              No longer want to use our service? You can delete your account
              here. This action is not reversible. All information related to
              this account will be deleted permanently.
            </p>
          </div>

          <form className="flex items-start md:col-span-2">
            <button
              type="submit"
              className="rounded-md bg-red-500 px-3 py-2 md:text-sm text-xs font-semibold text-white shadow-sm hover:bg-red-400"
            >
              Yes, delete my account
            </button>
          </form>
        </div>
      </div>
    )
  );
}

export default Account;
