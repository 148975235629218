import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Companies } from "../services/companies";
import { Jobs } from "../services/job";

const company = new Companies();
const job = new Jobs();

export const fetchCompany = createAsyncThunk(
  "companySlice/fetchCompany",
  async () => {
    return await company.getAuthCompany();
  }
);
export const fetchAllCompanies = createAsyncThunk(
  "companySlice/fetchAllCompanies",
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState();
    const { query } = state.UserSlice;
    const { page, limit } = state.companySlice;
    return await company.getCompaniesAdmin(page, limit, query);
  }
);
export const fetchAllJobCompany = createAsyncThunk(
  "companySlice/fetchAllJobCompany",
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState();
    const { job_page, job_limit, job_search } = state.companySlice;
    return await job.getCompanyJobs(job_page, job_limit, job_search);
  }
);
export const fetchAllCompaniesCandidate = createAsyncThunk(
  "companySlice/fetchAllCompaniesCandidate",
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState();
    const { query } = state.UserSlice;
    const { page, limit } = state.companySlice;
    return await company.getCompanies(page, limit, query);
  }
);
export const fetchAllCompaniesList = createAsyncThunk(
  "companySlice/fetchAllCompaniesList",
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState();
    const { query } = state.companySlice;
    return await company.getCompaniesAdminList(query);
  }
);

export const addCardCompany = createAsyncThunk(
  "companySlice/addCardCompany",
  async (arg, ThunkAPI) => {
    const state = ThunkAPI.getState();

    const { number, exp, cvc } = state.companySlice;
    const [exp_month, exp_year] = exp.split("/");

    await company.addCreditCard({
      number,
      exp_month,
      exp_year,
      cvc,
    });

    return await company.getAuthCompany();
  }
);

export const updateProfileCompany = createAsyncThunk(
  "companySlice/updateProfile",
  async ({ id, params = {} }) => {
    await company.updateProfile(id, params);

    return await company.getAuthCompany();
  }
);
export const postLogo = createAsyncThunk(
  "companySlice/postLogo",
  async ({ id, file }) => {
    await company.postLogo(id, file);
    return await company.getAuthCompany();
  }
);

export const companySlice = createSlice({
  name: "companySlice",
  initialState: {
    company: null,
    list: [],
    companies: [],
    onLoad: false,
    modalOpen: false,
    modalCardOpen: false,
    cvc: null,
    number: null,
    exp: null,
    page: 1,
    limit: 10,
    query: "",
    selected: {},
    job_page: 1,
    job_limit: 10,
    job_search: "",
    jobs: [],
  },
  reducers: {
    setOpen: (state) => {
      state.modalOpen = !state.modalOpen;
    },
    setModalCardOpen: (state) => {
      state.modalCardOpen = !state.modalCardOpen;
    },
    setNumber: (state, action) => {
      state.number = action.payload;
    },
    setCvc: (state, action) => {
      state.cvc = action.payload;
    },
    setExp: (state, action) => {
      state.exp = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    setJobPage: (state, action) => {
      state.job_page = action.payload;
    },
    setJobLimit: (state, action) => {
      state.job_limit = action.payload;
    },
    setJobSearch: (state, action) => {
      state.job_search = action.payload;
    },
    setSelected: (state, action) => {
      state.selected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addCardCompany.pending, (state) => {
        state.onLoad = true;
      })
      .addCase(fetchAllJobCompany.fulfilled, (state, action) => {
        state.jobs = action.payload;
      })

      .addCase(addCardCompany.fulfilled, (state, action) => {
        state.onLoad = true;
        state.modalCardOpen = !state.modalCardOpen;
        state.company = action.payload;
      })
      .addCase(fetchCompany.pending, (state) => {
        state.onLoad = true;
      })
      .addCase(fetchCompany.fulfilled, (state, action) => {
        state.onLoad = false;
        state.company = action.payload;
      })
      .addCase(fetchAllCompanies.fulfilled, (state, action) => {
        state.companies = action.payload;
      })
      .addCase(fetchAllCompaniesCandidate.fulfilled, (state, action) => {
        state.companies = action.payload;
      })
      .addCase(fetchAllCompaniesList.fulfilled, (state, action) => {
        state.list = action.payload;
      })
      .addCase(updateProfileCompany.pending, (state) => {
        state.onLoad = true;
      })
      .addCase(updateProfileCompany.fulfilled, (state, action) => {
        state.onLoad = false;
        state.company = action.payload;
      })
      .addCase(postLogo.pending, (state) => {
        state.onLoad = true;
      })
      .addCase(postLogo.fulfilled, (state, action) => {
        state.onLoad = false;
        state.company = action.payload;
      });
  },
});

export const {
  setModalCardOpen,
  setNumber,
  setExp,
  setCvc,
  setPage,
  setLimit,
  setQuery,
  setSelected,
  setJobPage,
  setJobSearch,
  setJobLimit,
} = companySlice.actions;
