import { useSelector } from "react-redux";

const stats = [
  {
    name: "Revenue",
    value: "$405,091.00",
    change: "+4.75%",
    changeType: "positive",
  },
  {
    name: "Overdue invoices",
    value: "$12,787.00",
    change: "+54.02%",
    changeType: "negative",
  },
  {
    name: "Outstanding invoices",
    value: "$245,988.00",
    change: "-1.39%",
    changeType: "positive",
  },
  {
    name: "Expenses",
    value: "$30,156.00",
    change: "+10.18%",
    changeType: "negative",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Stats() {
  const { stats } = useSelector((state) => state.ShiftSlice);
  return (
    <>
      <dl className="mx-auto grid grid-cols-1 gap-px bg-sky-900/5 sm:grid-cols-2 lg:grid-cols-4">
        <div className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8">
          <dt className="text-sm font-medium leading-6 text-sky-500">
            Revenue
          </dt>
          <dd className={classNames("text-xs font-medium")}></dd>
          <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
            USD {stats.totalRevenue}
          </dd>
        </div>
        <div className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8">
          <dt className="text-sm font-medium leading-6 text-sky-500">
            Total hours
          </dt>
          <dd className={classNames("text-xs font-medium")}></dd>
          <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-sky-900">
            {stats.totalHours} H
          </dd>
        </div>
        <div className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8">
          <dt className="text-sm font-medium leading-6 text-sky-500">
            Expenses
          </dt>
          <dd className={classNames("text-xs font-medium")}></dd>
          <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-sky-900">
            USD {stats.totalExpenses}
          </dd>
        </div>
        <div className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white px-4 py-10 sm:px-6 xl:px-8">
          <dt className="text-sm font-medium leading-6 text-sky-500">
            Upcoming shift
          </dt>
          <dd className={classNames("text-xs font-medium")}></dd>
          <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-sky-900">
            {stats.validShiftsCount}
          </dd>
        </div>
      </dl>
    </>
  );
}
