import React from "react";
import UsersD from "../../../components/details-user/users";
import { PlusIcon } from "@heroicons/react/24/outline";
import Search from "../../../components/search/search";
import AddNewUser from "../../../components/utils/addNewUser";

function Users() {
  return (
    <div className="w-full bg-red-">
      <h1 className=" font-semibold text-sky-600">All users</h1>
      <p className="mb-6 text-sm text-gray-400">
        Find bellow all users registred on the platform
      </p>
      <Search />
      <AddNewUser />
      <UsersD />
    </div>
  );
}

export default Users;
