import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { updateProfileCandidate } from "../../redux/candidate";
import { updateProfileCompany } from "../../redux/company";

export default function ModalActivate() {
  const { modalOpen, company } = useSelector((state) => state.companySlice);
  const cancelButtonRef = useRef(null);
  const dispatch = useDispatch();

  return (
    <Transition.Root show={modalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => {
          dispatch({
            type: "companySlice/setOpen",
          });
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all mb-20 sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <svg
                      id="Capa_1"
                      enable-background="new 0 0 512 512"
                      viewBox="0 0 512 512"
                      className="h-9 w-9"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g>
                        <g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <path
                                          d="m376.655 396.303-101.461-60.084c-6.201-3.675-8.249-11.681-4.575-17.882 0 0 2.988-5.043 6.186-10.443l-24.464-14.472c-16.138-9.261-23.685-10.974-38.244-5.345l-15.868 6.134c-9.656 3.733-20.491 2.832-29.398-2.445l-42.471-25.042-60.323 101.821 54.384 32.099c3.684 2.182 6.919 5.045 9.534 8.436l18.326 23.767c10.122 11.839 31.012 27.195 44.413 35.134l41.898 24.822c6.755 4.002 15.277 3.436 21.443-1.425z"
                                          fill="#f9eae2"
                                        />
                                        <path
                                          d="m396.114 407.826-44.268-26.216c-.549-.325-1.258-.154-1.597.387-.237.377-.231.85-.004 1.233 3.802 6.401 3.921 14.701-.601 21.41-5.684 8.433-17.178 10.642-25.927 5.459l-6.621-3.922c-1.374-.814-3.149-.353-3.953 1.028-.454.78-.515 1.731-.162 2.562 2.192 5.165 1.717 12.368-.751 17.852-3.541 7.87-12.785 13.27-22.671 7.414l-2.875-1.703c-1.97-1.167-4.461-.975-6.228.481-1.849 0-2.859 1.428-2.348 3.205 1.496 5.206.889 11.005-2.179 16.005-5.762 9.392-18.223 12.106-27.703 6.49l-7.801-4.622c-1.493-.885-3.412-.522-4.479.847-.65.834-.884 1.92-.62 2.944 1.277 4.967.665 10.418-2.156 15.179-3.209 5.417-8.574 8.759-14.354 9.6l43.421 25.724c9.065 5.37 20.961 2.895 26.649-5.973 5.972-9.31 2.993-21.634-6.442-27.223l21.451 12.709c9.065 5.37 20.961 2.895 26.65-5.973 5.972-9.31 2.993-21.634-6.441-27.223l14.568 8.631c9.232 5.47 21.151 2.419 26.62-6.813 5.469-9.232 2.419-21.151-6.813-26.62l17.428 10.325c9.064 5.37 20.959 2.895 26.647-5.973 5.972-9.312 2.994-21.635-6.44-27.224z"
                                          fill="#f5dbcc"
                                        />
                                      </g>
                                      <g>
                                        <path
                                          d="m385.641 272.805-32.62 18.96c-8.907 5.277-19.742 6.178-29.399 2.445l-17.896-7.03c-5.597-2.199-11.743-2.588-17.573-1.113l-27.419 6.939c-12.764 3.23-21.705 14.716-21.705 27.882v36.601c0 5.175 4.194 9.384 9.369 9.388 15.606.01 28.26-12.638 28.26-28.241v-1.55l117.362 69.501c2.174-2.316 4.667-4.319 7.409-5.943l44.532-26.018z"
                                          fill="#ecb784"
                                        />
                                      </g>
                                      <g fill="#ecb784">
                                        <path d="m144.255 422.207-11.003 15.326c-6.256 8.715-18.393 10.707-27.107 4.451-8.715-6.256-10.707-18.393-4.451-27.107l11.003-15.326c6.256-8.715 18.393-10.707 27.107-4.451 8.715 6.257 10.707 18.393 4.451 27.107z" />
                                        <path d="m187.957 427.949-23.147 32.241c-6.256 8.715-18.393 10.707-27.107 4.451-8.715-6.256-10.707-18.393-4.451-27.107l23.146-32.241c6.256-8.715 18.393-10.707 27.107-4.451 8.715 6.256 10.708 18.392 4.452 27.107z" />
                                        <path d="m219.515 450.605-23.146 32.241c-6.256 8.715-18.393 10.707-27.107 4.451-8.715-6.256-10.707-18.393-4.451-27.107l23.146-32.241c6.256-8.715 18.393-10.707 27.107-4.451 8.714 6.256 10.707 18.393 4.451 27.107z" />
                                        <path d="m240.788 487.587-7.697 10.721c-6.256 8.715-18.393 10.707-27.107 4.451-8.715-6.256-10.707-18.393-4.451-27.107l7.697-10.721c6.256-8.715 18.393-10.707 27.107-4.451 8.715 6.256 10.708 18.393 4.451 27.107z" />
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </g>
                          <g>
                            <g fill="#407093">
                              <path d="m414.442 378.568c23.055-13.742 37.177-38.602 37.177-65.442v-91.42l-5.656-8.937-85.995 51.259c-7.777 4.636-10.336 14.691-5.722 22.48l56.005 94.534z" />
                              <path d="m428.317 400.995c2.203.455 4.502.461 6.751-.02-2.096.085-4.373.09-6.751.02z" />
                            </g>
                            <path
                              d="m445.963 212.769v100.357c0 26.84-14.123 51.699-37.177 65.442l8.716 14.713c4.632 7.819 14.734 10.39 22.541 5.737l60.398-36.002c6.874-4.098 11.085-11.51 11.085-19.513v-154.419c0-6.787-7.395-10.988-13.225-7.513z"
                              fill="#365e7d"
                            />
                            <g>
                              <path
                                d="m13.698 181.571 138.333 82.456c7.777 4.636 10.336 14.691 5.721 22.48l-63.254 106.773c-4.632 7.819-14.734 10.39-22.541 5.737l-60.398-36.002c-6.874-4.098-11.085-11.51-11.085-19.513v-154.418c0-6.787 7.394-10.988 13.224-7.513z"
                                fill="#407093"
                              />
                            </g>
                          </g>
                          <g>
                            <g>
                              <g>
                                <g>
                                  <g>
                                    <g>
                                      <g>
                                        <g>
                                          <g>
                                            <g>
                                              <g>
                                                <g>
                                                  <g>
                                                    <path
                                                      d="m294.13 59.507c-4.26 0-7.712-3.452-7.712-7.712v-26.324c0-5.54-4.505-10.047-10.043-10.047h-40.749c-5.538 0-10.043 4.507-10.043 10.047v26.324c0 4.26-3.453 7.712-7.712 7.712s-7.712-3.452-7.712-7.712v-26.324c-.001-14.044 11.424-25.471 25.466-25.471h40.749c14.042 0 25.467 11.427 25.467 25.471v26.324c.001 4.26-3.452 7.712-7.711 7.712z"
                                                      fill="#365e7d"
                                                    />
                                                  </g>
                                                </g>
                                                <g>
                                                  <path
                                                    d="m184.909 106.812c-21.336 0-38.632-17.296-38.632-38.632v103.738c0 11.774 9.545 21.319 21.319 21.319h117.666c11.774 0 21.319-9.545 21.319-21.319v-65.106z"
                                                    fill="#ddb7a0"
                                                  />
                                                  <g>
                                                    <path
                                                      d="m327.091 106.812h-26.422v65.106c0 11.774-9.545 21.319-21.319 21.319h65.053c11.774 0 21.319-9.545 21.319-21.319v-103.738c.001 21.335-17.295 38.632-38.631 38.632z"
                                                      fill="#d6a889"
                                                    />
                                                    <path
                                                      d="m285.323 45.403h-117.726c-11.774 0-21.318 9.544-21.319 21.318v9.126c0 21.336 17.296 38.632 38.632 38.632h121.733v-47.757c0-11.775-9.545-21.319-21.32-21.319z"
                                                      fill="#cc9675"
                                                    />
                                                    <path
                                                      d="m300.669 66.722v47.756h26.422c21.336 0 38.632-17.296 38.632-38.632v-9.126c-.001-11.774-9.545-21.318-21.319-21.318h-65.054c11.774.001 21.319 9.545 21.319 21.32z"
                                                      fill="#c4845c"
                                                    />
                                                  </g>
                                                </g>
                                              </g>
                                            </g>
                                          </g>
                                        </g>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                            <g>
                              <g>
                                <path
                                  d="m198.657 138.385c-4.259 0-7.712-3.452-7.712-7.712v-32.39c0-4.26 3.453-7.712 7.712-7.712s7.712 3.452 7.712 7.712v32.391c0 4.259-3.453 7.711-7.712 7.711z"
                                  fill="#f4fbff"
                                />
                              </g>
                              <g>
                                <path
                                  d="m313.343 138.385c-4.26 0-7.712-3.452-7.712-7.712v-32.39c0-4.26 3.452-7.712 7.712-7.712s7.712 3.452 7.712 7.712v32.391c0 4.259-3.452 7.711-7.712 7.711z"
                                  fill="#f4fbff"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      You are in the right place{" "}
                      <span className="font-bold">
                        {company && company.name}
                      </span>
                      !
                    </Dialog.Title>
                    <div className="mt-2">
                      {company &&
                      company.industry &&
                      company.description &&
                      company.crn &&
                      company.vat ? (
                        <p className="text-sm text-gray-500">
                          You have completed your profile please enable your
                          visibility by clicking{" "}
                          <q className="text-sky-400 font-bold">Activate </q>{" "}
                          below to publish this job !
                        </p>
                      ) : (
                        <p className="text-sm text-gray-500">
                          To publish this job , please complete your company
                          profile !
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  {company &&
                  company.industry &&
                  company.description &&
                  company.crn &&
                  company.vat ? (
                    <button
                      onClick={() => {
                        dispatch(
                          updateProfileCompany({
                            id: company.id,
                            params: {
                              visible: true,
                            },
                          })
                        );
                        dispatch({
                          type: "companySlice/setOpen",
                        });
                      }}
                      className="inline-flex w-full justify-center rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600 sm:col-start-2"
                    >
                      Activate
                    </button>
                  ) : (
                    <a
                      href={"/profile"}
                      className="inline-flex w-full justify-center rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600 sm:col-start-2"
                    >
                      Complete
                    </a>
                  )}

                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                    onClick={() => {
                      dispatch({
                        type: "companySlice/setOpen",
                      });
                    }}
                    ref={cancelButtonRef}
                  >
                    Later
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
