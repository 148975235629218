import { FadeIn } from "./FadeIn";

import { Offices } from "./Offices";
import { Link } from "react-router-dom";

export function ContactSection() {
  return (
    <div className="mt-24 sm:mt-32 lg:mt-40">
      <FadeIn className="-mx-6 rounded-[2.5rem] bg-sky-600 px-6 py-20 sm:mx-0 sm:py-32 md:px-12">
        <div className="mx-auto max-w-4xl">
          <div className="max-w-xl">
            <h2 className="font-display text-3xl font-medium text-white [text-wrap:balance] sm:text-4xl">
              Hit Us Up !
            </h2>
            <div className="mt-6 flex">
              <Link
                to="/contact"
                className="inline-flex rounded-full px-4 py-1.5 text-sm font-semibold transition bg-white text-neutral-950 hover:bg-neutral-200"
              >
                Say Hey !
              </Link>
            </div>
            <div className="mt-10 border-t border-white/10 pt-10">
              <h3 className="font-display text-base font-semibold text-white">
                Our offices
              </h3>
              <Offices
                invert
                className="mt-6 grid grid-cols-1 gap-8 sm:grid-cols-2"
              />
            </div>
          </div>
        </div>
      </FadeIn>
    </div>
  );
}
