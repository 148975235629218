import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { baseUrl } from "../../../utils";
import { setModalCardOpen } from "../../../redux/company";
import moment from "moment";
import TableFinance from '../Finance/table';

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Card({ item }) {
  const { company } = useSelector((state) => state.companySlice);
  const dispatch = useDispatch();
  return (
    <li class="overflow-hidden rounded-xl border border-gray-200">
      <div class="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
        <div className="w-full mx-auto  bg-gradient-to-r from-teal-600 to-sky-600 gradient rounded-lg overflow-hidden ">
          <div className="md:flex">
            <div className="w-full p-4">
              <div className="flex justify-between items-center text-white">
                <img
                  src={[baseUrl, company.logo].join("")}
                  className="w-12"
                  alt="logo"
                />
                <i className="fa fa-chevron-circle-up fa-2x text-gray-300"></i>
              </div>

              <div className="flex justify-between items-center mt-10">
                <div className="flex flex-row">
                  <img
                    className="w-4 h-4"
                    src="https://i.imgur.com/FNef1H8.png"
                  />
                  <img
                    className="w-4 h-4"
                    src="https://i.imgur.com/FNef1H8.png"
                  />
                  <img
                    className="w-4 h-4"
                    src="https://i.imgur.com/FNef1H8.png"
                  />
                  <img
                    className="w-4 h-4"
                    src="https://i.imgur.com/FNef1H8.png"
                  />
                </div>

                <div className="flex flex-row">
                  <img
                    className="w-4 h-4"
                    src="https://i.imgur.com/FNef1H8.png"
                  />
                  <img
                    className="w-4 h-4"
                    src="https://i.imgur.com/FNef1H8.png"
                  />
                  <img
                    className="w-4 h-4"
                    src="https://i.imgur.com/FNef1H8.png"
                  />
                  <img
                    className="w-4 h-4"
                    src="https://i.imgur.com/FNef1H8.png"
                  />
                </div>

                <div className="flex flex-row">
                  <img
                    className="w-4 h-4"
                    src="https://i.imgur.com/FNef1H8.png"
                  />
                  <img
                    className="w-4 h-4"
                    src="https://i.imgur.com/FNef1H8.png"
                  />
                  <img
                    className="w-4 h-4"
                    src="https://i.imgur.com/FNef1H8.png"
                  />
                  <img
                    className="w-4 h-4"
                    src="https://i.imgur.com/FNef1H8.png"
                  />
                </div>

                <div className="flex flex-row">
                  <span className="text-white text-lg mr-1 font-bold">
                    {item.last4}
                  </span>
                </div>
              </div>

              <div className="mt-8 flex justify-between items-center text-white">
                <div className="flex flex-col">
                  <span className="font-bold text-gray-300 text-sm">
                    Company
                  </span>
                  <span className="font-bold text-sm">{company.name}</span>
                </div>

                <div className="flex flex-col">
                  <span className="font-bold text-gray-300 text-sm">
                    Expires
                  </span>
                  <span className="font-bold">
                    {[item.exp_month, item.exp_year].join("/")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <dl class="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
        <div class="flex justify-between gap-x-4 py-3">
          <dt class="text-gray-500">Created</dt>
          <dd class="text-gray-700">
            <time datetime="2022-12-13">
              {moment.unix(1694463805).fromNow()}
            </time>
          </dd>
        </div>
      </dl>
    </li>
  );
}

function Stats() {
  const { company, onLoad } = useSelector((state) => state.companySlice);
  return (
    <div className="max-w-[85rem] py-6 px-4 sm:px-6 lg:px-8 mx-auto">
      <div className="grid gap-6 grid-cols-2 sm:gap-12 lg:grid-cols-3 lg:gap-8">
        <div className="flex flex-col items-center">
          <h4 className="text-sm md:text-xl font-semibold text-gray-800 dark:text-gray-200">
            Total Hours
          </h4>
          <p className="mt-2 sm:mt-3 text-2xl text-center sm:text-5xl font-bold text-sky-500">
            {company.finance.totalHours} H
          </p>
          <p className="mt-1 text-sm text-gray-500 text-center">
            Number of hour spent
          </p>
        </div>

        <div className="flex flex-col items-center">
          <h4 className="text-sm md:text-xl  font-semibold text-gray-800 dark:text-gray-200">
            Total paied
          </h4>
          <p className="mt-2 sm:mt-3 text-2xl text-center sm:text-5xl font-bold text-green-500">
            {[company.finance.totalSpent, company.currency.code].join(" ")}
          </p>
          <p className="mt-1 text-sm text-center text-gray-500">
            Total money to candidates{" "}
          </p>
        </div>

        <div className="flex flex-col items-center col-span-2 lg:col-span-1">
          <h4 className="text-sm md:text-xl  font-semibold text-gray-800 dark:text-gray-200">
            No show
          </h4>
          <p className="mt-2 sm:mt-3 text-2xl sm:text-5xl font-bold text-red-500">
            {company.finance.noShowCount}
          </p>
          <p className="mt-1 text-gray-500 text-sm text-center">
            Number of candidates absent
          </p>
        </div>
      </div>
    </div>
  );
}

function Finance() {
  const { company, onLoad } = useSelector((state) => state.companySlice);
  const dispatch = useDispatch();
  return (
    <div className="flex flex-col">
      <Stats />
      <hr />
      <div className="p-5">
        <h1 className="text-2xl font-semibold text-center">
          Billing information
        </h1>
      </div>
      <hr />
      <div className="p-2">
        {company.user &&
          company.user.stripe &&
          company.user.stripe.requirements.currently_due.length > 0 && (
            <a
              type="button"
              href={[
                baseUrl,
                `/api/stripe/create-account/${company.user.id}`,
              ].join("")}
              target="_top"
              className="p-5 flex justify-center  my-5 items-center flex-col col-span-full rounded-lg border-dashed border-2"
            >
              <span className="font-medium text-center ">
                We need you accept terms of Stripe Service Agreement to let you
                handle your finance with ease . <br />{" "}
                <p className="text-xs text-indigo-600 leading-3 md:text-sm">
                  required your account number / IBAN
                </p>{" "}
              </span>
              <p className="text-sky-600 text-lg font-medium animate-pulse mt-4">
                Click here to update it !
              </p>
            </a>
          )}

        <h1 className="text-lg font-semibold">
          See here yours company credit cards
        </h1>

        <ul
          role="list"
          class="grid  md:grid-cols-2  xl:grid-cols-3 gap-x-6 gap-y-8 mt-4  xl:gap-x-2"
        >
          {company.user &&
            company.user.stripe &&
            company.user.stripe.paymentMethods.data.map((item) => (
              <Card item={item.card} />
            ))}

          {company.user.stripe && (
            <a
              type="button"
              onClick={() => dispatch(setModalCardOpen())}
              className="p-5 flex justify-center items-center flex-col rounded-lg border-dashed border-2"
            >
              <span className="text-center">
                Want add a new card to your wallet ?
              </span>
              <p className="text-sky-600 animate-pulse font-medium text-center">
                Add new card .
              </p>
            </a>
          )}
        </ul>
      </div>
      <TableFinance />
    </div>
  );
}

export default Finance;
