import { Fragment } from "react";
import {
  ArrowDownCircleIcon,
  ArrowPathIcon,
  ArrowUpCircleIcon,
} from "@heroicons/react/20/solid";
import { useTableFinance } from "../../hook/shift";
import { useDispatch, useSelector } from "react-redux";
import { setDateF, setPage } from "../../redux/shift";
import { setInvoiceId } from "../../redux/stripe";
import { Link } from "react-router-dom";

const statuses = {
  Paid: "text-green-700 bg-green-50 ring-green-600/20",
  Pending: "text-gray-600 bg-gray-100 animate-pulse ring-gray-500/10",
  CANCEL: "text-red-600 bg-red-50 ring-red-500/10",
  NO_SHOW: "text-white bg-yellow-500 ring-yellow-600/10",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TableFinanceHistory() {
  const { tableFinance, limit, page } = useSelector(
    (state) => state.ShiftSlice
  );
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1); // Début de la journée d'hier
  const threeAgo = new Date(today);
  threeAgo.setDate(threeAgo.getDate() - 3);

  useTableFinance();
  const dispatch = useDispatch();
  console.log(today.toISOString()[0]);

  const start = (page - 1) * limit + 1;
  const end = Math.min(page * limit, tableFinance.total);
  return (
    <div>
      <nav
        className="flex  w-full items-center justify-between  bg-white px-4 py-3 sm:px-6"
        aria-label="Pagination"
      >
        <div className="hidden sm:block">
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{start}</span> to{" "}
            <span className="font-medium">{end}</span> of{" "}
            <span className="font-medium">{tableFinance.total}</span> results
          </p>
        </div>
        <div className="flex flex-1 justify-between sm:justify-end">
          <button
            onClick={() => dispatch(setPage(page - 1))}
            disabled={start === 1}
            className="disabled:bg-slate-400/40 disabled:text-white relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
          >
            Previous
          </button>
          <button
            disabled={end === tableFinance.total}
            onClick={() => dispatch(setPage(page + 1))}
            className="disabled:bg-slate-400/40 disabled:text-white relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
          >
            Next
          </button>
        </div>
      </nav>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"></div>
      <div className="mt-6 overflow-hidden border-t border-gray-100">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            <div class="flex py-6 flex-colum sm:flex-row flex-wrap space-y-4 sm:space-y-0 items-center justify-between pb-4">
              <div>
                <select
                  id="filter"
                  name="filter"
                  onChange={(e) => dispatch(setDateF(e.currentTarget.value))}
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-sky-600 sm:text-sm sm:leading-6"
                >
                  <option value={today.toISOString()}>Today</option>
                  <option value={yesterday.toISOString()}>Yesterday</option>
                  <option value={threeAgo.toISOString()}>3 days ago</option>
                </select>
              </div>
              <label for="table-search" class="sr-only">
                Search
              </label>
              <div class="relative">
                <div class="absolute inset-y-0 left-0 rtl:inset-r-0 rtl:right-0 flex items-center ps-3 pointer-events-none">
                  <svg
                    class="w-5 h-5 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
                <input
                  type="date"
                  onChange={(e) => dispatch(setDateF(e.currentTarget.value))}
                  id="table-search"
                  class="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search for items"
                />
              </div>
            </div>
            <table className="w-full text-left">
              <thead className="sr-only">
                <tr>
                  <th>Amount</th>
                  <th className="hidden sm:table-cell">Client</th>
                  <th>More details</th>
                </tr>
              </thead>

              <tbody>
                {tableFinance.results &&
                  tableFinance.results.map((day) => (
                    <Fragment key={day.dateTime}>
                      <tr className="text-sm leading-6 text-gray-900">
                        <th
                          scope="colgroup"
                          colSpan={3}
                          className="relative isolate py-2 font-semibold"
                        >
                          <time dateTime={day.dateTime}>{day.date}</time>
                          <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                          <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                        </th>
                      </tr>
                      {day.transactions.map((transaction) => (
                        <tr key={transaction.id}>
                          <td className="relative py-5 pr-6">
                            <div className="flex gap-x-6">
                              <ArrowDownCircleIcon
                                className="hidden h-6 w-5 flex-none text-gray-400 sm:block"
                                aria-hidden="true"
                              />
                              <div className="flex-auto">
                                <div className="flex items-start gap-x-3">
                                  <div className="text-sm font-medium leading-6 text-gray-900">
                                    {transaction.amount}
                                  </div>
                                  <div className="flex flex-col ">
                                    <div
                                      className={classNames(
                                        statuses[transaction.status],
                                        "rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset"
                                      )}
                                    >
                                      {transaction.status}
                                    </div>
                                  </div>
                                </div>
                                {transaction.tax ? (
                                  <div className="mt-1 text-xs leading-5 text-gray-500">
                                    {transaction.tax} tax
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                            <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                          </td>
                          <td className="hidden py-5 pr-6 sm:table-cell">
                            <div className="text-sm leading-6 text-gray-900">
                              {transaction.candidate}
                            </div>
                            <div className="mt-1 text-xs leading-5 text-gray-500">
                              {transaction.jobName}
                            </div>
                          </td>
                          <td className="py-5 text-right">
                            <div className="flex justify-end">
                              <Link
                                to={
                                  transaction.invoiceNumber ? "/invoices" : ""
                                }
                                onClick={() =>
                                  dispatch(
                                    setInvoiceId(transaction.invoiceNumber)
                                  )
                                }
                                className="text-sm font-medium leading-6 text-sky-600 hover:text-sky-500"
                              >
                                View
                                <span className="hidden sm:inline">
                                  {" "}
                                  transaction
                                </span>
                                <span className="sr-only">
                                  , invoice #{transaction.invoiceNumber},{" "}
                                  {transaction.candidate}
                                </span>
                              </Link>
                            </div>
                            <div className="mt-1 text-xs leading-5 text-gray-500">
                              Invoice{" "}
                              <span className="text-gray-900">
                                #{transaction.invoiceNumber}
                              </span>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </Fragment>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
