import { useUsers } from "../../../hook/users";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { baseUrl } from "../../../utils";
import Roles from "../../../Enum/UserRoles";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { setUserToSend } from "../../../redux/conversation";

export default function UserList() {
  useUsers();

  const { users, page, limit } = useSelector((state) => state.UserSlice);
  const dispatch = useDispatch();

  const start = (page - 1) * limit + 1;
  const end = Math.min(page * limit, users.total);

  return (
    <ul
      role="list"
      className="divide-y divide-gray-100 w-full h-[calc(100vh-14rem)]  overflow-hidden hover:overflow-y-auto"
    >
      {users.users &&
        users.users.map((person) => (
          <li
            key={person.email}
            className={`flex flex-row cursor-pointer hover:bg-slate-50 gap-x-4 py-5 ${
              person?.conversation &&
              person.conversation.lastMessage &&
              !person.conversation.lastMessage.readAt &&
              person.conversation.lastMessage.sender !== Roles.admin
                ? "bg-red-50 text-indigo-800"
                : ""
            }`}
            onClick={() =>
              dispatch(
                setUserToSend({
                  id:
                    person.roles && person.roles.includes(Roles.candidate)
                      ? person.candidate.id
                      : person.company.id,
                  role:
                    person.roles && person.roles.includes(Roles.candidate)
                      ? Roles.candidate
                      : Roles.company,

                  image:
                    person.roles && person.roles.includes(Roles.candidate)
                      ? person.candidate?.candidateProfile?.profile?.image
                      : person?.company?.logo,

                  name:
                    person.roles && person.roles.includes(Roles.candidate)
                      ? [
                          person.candidate?.firstName,
                          person.candidate?.lastName,
                        ].join(" ")
                      : person?.company?.name,
                  idTo: person.id,
                  convId: person?.conversation?.lastMessage?.conversationId,
                })
              )
            }
          >
            <img
              className="h-11 w-11 rounded-full"
              src={
                person.roles && person.roles.includes(Roles.candidate)
                  ? [
                      baseUrl,
                      person.candidate?.candidateProfile?.profile?.image,
                    ].join("")
                  : person.roles.includes(Roles.company)
                  ? [(baseUrl, person?.company?.logo)].join("")
                  : require("../../../assets/img/gear.png")
              }
              alt="image"
            />
            <div className="min-w-0">
              <p className="text-sm font-semibold leading-6 text-gray-900">
                {person.roles.includes(Roles.candidate) &&
                  person.candidate?.lastName}

                {person.roles.includes(Roles.company) && person.company?.name}
              </p>
              <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                {person.email}
              </p>
            </div>

            <div className="flex justify-end flex-shrink w-full">
              <ChevronRightIcon
                className="h-5 w-5 flex-none text-gray-400"
                aria-hidden="true"
              />
            </div>
          </li>
        ))}
    </ul>
  );
}
