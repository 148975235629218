import {
  BriefcaseIcon,
  ChartPieIcon,
  ChatBubbleBottomCenterTextIcon,
  PaperClipIcon,
  PencilIcon,
  TrashIcon,
  UserCircleIcon,
  UserGroupIcon,
} from "@heroicons/react/20/solid";
import { ChartBarIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

export default function ToolBar() {
  return (
    <div className="relative mt-6">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className="relative flex justify-center">
        <span className="isolate inline-flex -space-x-px rounded-md shadow-sm">
          <Link
            to="/users"
            className="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
          >
            <span className="sr-only">Edit</span>
            <UserGroupIcon className="h-5 w-5" aria-hidden="true" />
          </Link>
          <Link
            to="/candidates"
            className="relative inline-flex items-center bg-white px-3 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
          >
            <span className="sr-only">Attachment</span>
            <BriefcaseIcon className="h-5 w-5" aria-hidden="true" />
          </Link>
          <Link
            to="/contact"
            className="relative inline-flex items-center bg-white px-3 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
          >
            <span className="sr-only">Annotate</span>
            <ChatBubbleBottomCenterTextIcon
              className="h-5 w-5"
              aria-hidden="true"
            />
          </Link>
          <Link
            to="/finance"
            className="relative inline-flex items-center rounded-r-md bg-white px-3 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
          >
            <span className="sr-only">Delete</span>
            <ChartPieIcon className="h-5 w-5" aria-hidden="true" />
          </Link>
        </span>
      </div>
    </div>
  );
}
