import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLimit } from "../redux/users";

function useGlobalState() {
  const location = useLocation();
  const dispatch = useDispatch();



  useEffect(() => {
    if (location.pathname === "/messages") {
      dispatch(setLimit(20));
    }
    if (location.pathname === "/") {
      dispatch(setLimit(5));
    }
    if (location.pathname === "/users") {
      dispatch(setLimit(8));
    }
  }, [location.pathname]);
}

export default useGlobalState;
