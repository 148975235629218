import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Stripe } from "../services/stripe";

const stripe = new Stripe();

export const fetchAllInvoices = createAsyncThunk(
  "stripeSlice/fetchAllInvoices",
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState();
    const {
      page,
      limit,
      invoice_id,
      customer_id,
      lastInvoiceId,
      firstInvoiceId,
      prev
    } = state.stripeSlice;
    return await stripe.getInvoices(
      page,
      limit,
      invoice_id,
      customer_id,
      lastInvoiceId,
      firstInvoiceId,
      prev
    );
  }
);

export const stripeSlice = createSlice({
  name: "stripeSlice",
  initialState: {
    page: 1,
    limit: 15,
    invoice_id: "",
    customer_id: "",
    lastInvoiceId: "",
    firstInvoiceId: "",
    invoices: [],
    prev: false,
  },
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    setInvoiceId: (state, action) => {
      state.invoice_id = action.payload;
    },
    setCustomerId: (state, action) => {
      state.customer_id = action.payload;
    },
    setCustomerId: (state, action) => {
      state.customer_id = action.payload;
    },
    setPrev: (state, action) => {
      state.prev = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllInvoices.fulfilled, (state, action) => {
      state.invoices = action.payload;
      if (action.payload) {
        state.lastInvoiceId = action.payload.lastInvoiceId;
        state.firstInvoiceId = action.payload.firstInvoiceId;
      }
    });
  },
});

export const {
  setPage,
  setCustomerId,
  setInvoiceId,
  setLimit,
  setlastInvoiceId,
  setPrev
} = stripeSlice.actions;
