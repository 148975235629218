import { useEffect, useState } from "react";
import { Industry } from "../services/industry";

const industry = new Industry();

export default function useIndustries() {
  const [datas, setDatas] = useState();

  function initialize(value) {
    setDatas(value);
  }

  useEffect(() => {
    fechData();
  }, []);

  function fechData() {
    industry.getIndustries().then(initialize);
  }

  return { datas };
}
