import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Stars from "../../../components/rating/Stars";
import useCandidate from "../../../hook/candidate";
import moment from "moment";

function Schedule({ events }) {
  const { open, availability, startTime, endTime } = useSelector(
    (state) => state.CalendarSlice
  );

  console.log(events);

  const timeSlotDuration = 30; // Duration of each time slot in minutes

  // Calculate the total number of time slots
  const totalSlots = Math.floor(
    (endTime - startTime) / (timeSlotDuration * 60 * 1000)
  );

  const calculateGridRow = (eventStartTime) => {
    const minutesDiff = Math.floor(
      (new Date(eventStartTime) - startTime) / (60 * 1000)
    );

    const slotCount = Math.floor(minutesDiff / timeSlotDuration);
    return slotCount + 2;
  };

  const filteredEvents = events.filter(
    (event) =>
      new Date(event.startTime).getTime() >= startTime.getTime() &&
      new Date(event.startTime).getTime() <= endTime.getTime()
  );

  const groupedEventsByDate = filteredEvents.reduce((groupedEvents, event) => {
    const eventDate = new Date(event.startTime).toDateString();
    if (!groupedEvents[eventDate]) {
      groupedEvents[eventDate] = [];
    }
    groupedEvents[eventDate].push(event);
    return groupedEvents;
  }, {});

  const scheduleItems = Object.keys(groupedEventsByDate).map((date) => {
    const eventsOnThisDay = groupedEventsByDate[date];
    const gridRowStart = calculateGridRow(eventsOnThisDay[0].startTime);
    const gridRowEnd =
      gridRowStart + Math.ceil(eventsOnThisDay[0].duration / timeSlotDuration);
    return (
      <li
        key={date}
        className={`relative overflow-scroll md:justify-center border-l-indigo-600 border-l-4  md:overflow-hidden  md:hover:overflow-scroll rounded-lg bg-sky-50 mt-px max-h-fit p-2 flex  flex-row`}
        style={{
          gridRow: `${gridRowStart} / span ${gridRowEnd - gridRowStart}`,
        }}
      >
        <div className="">
          {eventsOnThisDay.map((event, index) => (
            <EventCard key={index} event={event} index={index} />
          ))}
        </div>
      </li>
    );
  });

  return (
    <ol
      className="col-start-1 col-end-2 row-start-1 grid grid-cols-1"
      style={{
        gridTemplateRows: `1.75rem repeat(${totalSlots}, minmax(0, 1fr)) auto`,
      }}
    >
      {scheduleItems}
    </ol>
  );
}

const EventCard = ({ event, index }) => {
  // Add your event card component implementation here...
  const dispatch = useDispatch();
  return (
    <div>
      <time
        dateTime={event.startTime}
        className=" w-96 md:w-[400px] flex justify-center items-center  p-5"
      >
        <div className="card py-2 px-2 shadow-md bg-white flex flex-col w-full border border-l-4 border-l-sky-400  ">
          <div className="flex flex-row">
            <div className="flex-1 flex-col flex justify-start items-start">
              <h1 className=" truncate whitespace-nowrap sm:text-lg lg:text-lg md:text-xl text-indigo-800 leading-8 font-extrabold ">
                {event.title}
              </h1>

              <div className="flex-1 inline-flex w-full flex-shrink items-center  justify-end text-xs sm:text-sm  text-indigo-900">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>

                <h1 className=" font-light text-[0.65rem] text-right">
                  {moment(event.startTime).add(10, "minutes").fromNow()}
                </h1>
              </div>
              <span className="text-xs to-zinc-400">{event.location}</span>
            </div>
          </div>
          <div className="flex-row flex">
            <div className="flex-1 inline-flex items-center space-x-2">
              <img src={event.imageUrl} className="w-7 h-7" alt="" srcset="" />
              <h1 className="text-sm font-medium">{event.candidateName}</h1>
            </div>
          </div>
          <hr className="my-2" />

          <div className="h-fit w-full md:hover:overflow-x-auto md:overflow-hidden justify-center overflow-scroll flex flex-nowrap space-x-4  ">
            <h1
              onClick={() =>
                dispatch({
                  type: "ShiftSlice/setShiftPunch",
                  payload: event.id,
                })
              }
              className="text-sky-500 font-medium animate-pulse cursor-pointer"
            >
              Shift punch
            </h1>
          </div>
          <hr className="my-2" />
          <div className="flex justify-center flex-wrap  py-2">
            <span className="bg-gray-100 text-gray-800 font-semibold text-xs mb-2 lg:text-sm  md:font-medium inline-flex items-center px-2.5 py-0.5 rounded mr-2 dark:bg-gray-700 dark:text-gray-400 border border-gray-500">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                />
              </svg>
              &nbsp; {event.startDate}
            </span>
            <span className="bg-blue-100 text-blue-800 text-xs mb-2 lg:text-sm font-semibold md:font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              &nbsp; {`${event.startHour} - ${event.endHour}`}
            </span>
          </div>
        </div>
      </time>
    </div>
  );
};

export default Schedule;
