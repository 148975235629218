import React, { useCallback, useEffect } from "react";
import Stats from "./stats";
import OursCompany from "../../../components/company/our_company";
import Users from "../../../components/details-user/users";
import ToolBar from "./tool-bar";
import { fetchAllCompanies, setLimit, setPage } from "../../../redux/company";
import { useDispatch } from "react-redux";
import { setQuery } from "../../../redux/users";
import TableFinanceHistory from "../../../components/finance/table";
import { ChevronDoubleDownIcon, PlusIcon } from "@heroicons/react/20/solid";

function Dashboard() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPage(1));
    dispatch(setQuery(""));
    dispatch(setLimit(3));
  }, []);

  return (
    <div className=" ">
      <h1 className="mb-6 font-semibold text-sky-600">Overview</h1>
      <Stats />
      <ToolBar />

      <h1 className="mb-6 font-semibold text-sky-600">Recent activity</h1>
      <TableFinanceHistory />

      <div className="relative  mt-6">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
        <div className="relative flex justify-center">
          <span className="bg-white px-2 text-gray-500">
            <ChevronDoubleDownIcon
              className="h-5 w-5 text-gray-500"
              aria-hidden="true"
            />
          </span>
        </div>
      </div>
      {/*  <div className=" grid grid-cols-2 mt-3 gap-3">
        <button
          type="button"
          className="rounded-md  bg-sky-50 px-3.5 py-2.5 text-sm font-semibold text-sky-600 shadow-sm hover:bg-sky-100"
        >
          Create new user
        </button>
        <button
          type="button"
          className="rounded-md  bg-sky-50 px-3.5 py-2.5 text-sm font-semibold text-sky-600 shadow-sm hover:bg-sky-100"
        >
          Create sub user
        </button>
      </div> */}
      <h1 className="my-6 font-semibold text-sky-600">Ours companies</h1>
      <OursCompany />

      <div className="relative  mt-6">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
        <div className="relative flex justify-center">
          <span className="bg-white px-2 text-gray-500">
            <ChevronDoubleDownIcon
              className="h-5 w-5 text-gray-500"
              aria-hidden="true"
            />
          </span>
        </div>
      </div>
      <h1 className="mt-8 font-semibold text-sky-600">
        Latest users registred
      </h1>
      <p className="mt-2 text-sm text-gray-700">
        A list of all the users in your account including their name, title,
        email and role.
      </p>
      <Users />
    </div>
  );
}

export default Dashboard;
