import React, { useState } from "react";
import useCandidate from "../../../hook/candidate";

import Account from "./Account";
import Notifications from "./Notifications";
import Finance from "./Finance";
import { useDispatch, useSelector } from "react-redux";
import { setStage } from "../../../redux/navigation";
import { useTableFinanceCandidate } from "../../../hook/shift";

function Profile() {
  const { datas } = useCandidate();
  const { candidate, onLoad } = useSelector((state) => state.candidateSlice);
  const { stage } = useSelector((state) => state.NavigationSlice);
  const dispatch = useDispatch();
  const secondaryNavigation = [
    { name: "Account", href: 0, current: stage === 0 },

    { name: "Notifications", href: 1, current: stage === 1 },
    { name: "Finance", href: 2, current: stage === 2 },
    { name: "ID Document", href: "#id-document", current: stage === 3 },
  ];

  useTableFinanceCandidate();

  return (
    <main className="md:max-w-7xl w-sm overflow-hidden">
      <header className="border-b border-gray-300">
        {/* Secondary navigation */}
        <nav className="flex overflow-x-auto py-4">
          <ul
            role="list"
            className="flex   flex-none gap-x-6 px-4 text-sm font-semibold leading-6 cursor-pointer text-gray-600 sm:px-6 lg:px-8"
          >
            {secondaryNavigation.map((item) => (
              <li key={item.name}>
                <a
                  href={item.href === "#id-document" ? item.href : null}
                  onClick={() =>
                    item.href === "#id-document"
                      ? dispatch(setStage(0))
                      : dispatch(setStage(item.href))
                  }
                  className={
                    item.current ? "text-sky-400 relative " : "relative "
                  }
                >
                  {datas &&
                    item.href === "#id-document" &&
                    (!datas?.user?.legalDocumentId?.path ||
                      !datas?.user?.legalDocumentId?.documentNumber ||
                      !datas?.user?.legalDocumentId?.issueDate ||
                      !datas?.user?.legalDocumentId?.expiryDate) && (
                      <span class="absolute top-0 -right-1 inline-flex items-center w-3.5 h-3.5 rounded-full border-2 border-white text-xs font-medium transform -translate-y-1/2 translate-x-1/2 bg-red-500 text-white">
                        <span class="sr-only">Badge value</span>
                      </span>
                    )}

                  {datas &&
                    ((item.href === 2 && datas.user && !datas.user.stripe) ||
                      (datas.user.stripe &&
                        datas.user.stripe.requirements.currently_due.length >
                          0 &&
                        item.href === 2)) && (
                      <span class="absolute top-0 -right-1 inline-flex items-center w-3.5 h-3.5 rounded-full border-2 border-white text-xs font-medium transform -translate-y-1/2 translate-x-1/2 bg-red-500 text-white">
                        <span class="sr-only">Badge value</span>
                      </span>
                    )}

                  {item.name}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </header>

      {/* Settings forms */}

      {datas && stage === 0 && <Account />}
      {datas && stage === 1 && <Notifications />}
      {datas && stage === 2 && <Finance />}
    </main>
  );
}

export default Profile;
