export const baseUrl =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_URL_PROD
    : process.env.REACT_APP_URL_LOCAL4;

export function truncateString(str, num) {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
}

export function formatName(name) {
  const parts = name.split(" ");

  if (parts.length === 1 && parts[0].length > 10) {
    return parts[0][0];
  }

  if (parts.length > 1) {
    let formattedName = "";
    for (const part of parts) {
      if (part.length > 5) {
        formattedName += part[0] + " ";
      } else {
        formattedName += part + " ";
      }
    }
    return formattedName.trim().replaceAll("-", " ");
  }

  return name;
}

export function isValidCardNumber(cardNumber) {
  // Retirer tous les espaces et tirets
  cardNumber = cardNumber.replace(/\s+/g, "").replace(/-/g, "");

  // Appliquer l'algorithme de Luhn
  let sum = 0;
  let shouldDouble = false;
  for (let i = cardNumber.length - 1; i >= 0; i--) {
    let digit = parseInt(cardNumber[i]);
    if (shouldDouble) {
      digit *= 2;
      if (digit > 9) {
        digit -= 9;
      }
    }
    sum += digit;
    shouldDouble = !shouldDouble;
  }
  return sum % 10 === 0;
}

export function getCardType(cardNumber) {
  // Retirer tous les espaces et tirets
  cardNumber = cardNumber.replace(/\s+/g, "").replace(/-/g, "");
  // Utiliser des expressions régulières pour déterminer le type de carte
  if (/^4[0-9]{12}(?:[0-9]{3})?$/.test(cardNumber)) {
    return "Visa";
  } else if (/^5[1-5][0-9]{14}$/.test(cardNumber)) {
    return "MasterCard";
  } else if (/^3[47][0-9]{13}$/.test(cardNumber)) {
    return "Amex";
  } else if (/^6(?:011|5[0-9]{2})[0-9]{12}$/.test(cardNumber)) {
    return "Discover";
  } else if (/^(?:2131|1800|35\d{3})\d{11}$/.test(cardNumber)) {
    return "JCB";
  } else if (/^3(?:0[0-5]|[68][0-9])[0-9]{11}$/.test(cardNumber)) {
    return "Diners Club";
  } else if (/^50[0-9]{14,19}$/.test(cardNumber)) {
    return "Aura";
  } else if (/^6011[0-9]{12}$/.test(cardNumber)) {
    return "Nexi";
  } else {
    return "Unknown";
  }
}

export function convertTo24HourFormat(timeStr) {
  const [time, period] = timeStr.split(" ");
  let [hours, minutes] = time.split(":");
  hours = parseInt(hours);
  minutes = parseInt(minutes);

  if (period === "PM" && hours !== 12) {
    hours += 12;
  } else if (period === "AM" && hours === 12) {
    hours = 0;
  }

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
}
export const styleMap = [
  {
    featureType: "administrative",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#d6e2e6",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#cfd4d5",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#7492a8",
      },
    ],
  },
  {
    featureType: "administrative.neighborhood",
    elementType: "labels.text.fill",
    stylers: [
      {
        lightness: 25,
      },
    ],
  },
  {
    featureType: "landscape.man_made",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#dde2e3",
      },
    ],
  },
  {
    featureType: "landscape.man_made",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#cfd4d5",
      },
    ],
  },
  {
    featureType: "landscape.natural",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#dde2e3",
      },
    ],
  },
  {
    featureType: "landscape.natural",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#7492a8",
      },
    ],
  },
  {
    featureType: "landscape.natural.terrain",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#dde2e3",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#588ca4",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.icon",
    stylers: [
      {
        saturation: -100,
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#a9de83",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#bae6a1",
      },
    ],
  },
  {
    featureType: "poi.sports_complex",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#c6e8b3",
      },
    ],
  },
  {
    featureType: "poi.sports_complex",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#bae6a1",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#41626b",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [
      {
        saturation: -45,
      },
      {
        lightness: 10,
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#c1d1d6",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#a6b5bb",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "road.highway.controlled_access",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#9fb6bd",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "labels.icon",
    stylers: [
      {
        saturation: -70,
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#b4cbd4",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#588ca4",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#008cb5",
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "transit.station.airport",
    elementType: "geometry.fill",
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: -5,
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#a6cbe3",
      },
    ],
  },
];

export const prefix = {
  UK: require("./assets/img/flag/united-kingdom_321269.png"),
  KSA: require("./assets/img/flag/saudi-arabia_321258.png"),
  UAE: require("./assets/img/flag/united-arab-emirates_321268.png"),
};
