import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { useFetchContact } from "../../../hook/contact";
import { useDispatch, useSelector } from "react-redux";
import {
  setInquirie,
  setOpenContact,
  setPageContact,
} from "../../../redux/users";

export default function Contact() {
  useFetchContact();
  const { contacts, page_contact, limit } = useSelector(
    (state) => state.UserSlice
  );
  const start = (page_contact - 1) * limit + 1;
  const end = Math.min(page_contact * limit, contacts.total);
  const dispatch = useDispatch();
  return (
    <>
      <h1 className=" font-semibold text-sky-600">Inquiries</h1>
      <p className="mb-6 text-sm">Reply quickly to secure your gig </p>
      <hr className="my-5" />

      <nav
        className="flex  w-full items-center justify-between  bg-white px-4 py-3 sm:px-6"
        aria-label="Pagination"
      >
        <div className="hidden sm:block">
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{start}</span> to{" "}
            <span className="font-medium">{end}</span> of{" "}
            <span className="font-medium">{contacts.total}</span> results
          </p>
        </div>
        <div className="flex flex-1 justify-between sm:justify-end">
          <button
            onClick={() => dispatch(setPageContact(page_contact - 1))}
            disabled={start === 1}
            className=" disabled:bg-slate-400/40 disabled:text-white relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
          >
            Previous
          </button>
          <button
            disabled={end === contacts.total}
            onClick={() => dispatch(setPageContact(page_contact + 1))}
            className="disabled:bg-slate-400/40 disabled:text-white relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
          >
            Next
          </button>
        </div>
      </nav>
      <ul role="list" className="divide-y divide-gray-100">
        {contacts.contacts &&
          contacts.contacts.map((person) => (
            <li
              key={person.data.email}
              onClick={() => {
                dispatch(setOpenContact());
                dispatch(setInquirie(person));
              }}
              className="relative flex justify-between gap-x-6 cursor-pointer px-4 py-5 hover:bg-gray-50 sm:px-6 lg:px-8"
            >
              <div className="flex min-w-0 gap-x-4">
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-6 text-gray-900">
                    <a href={person.href}>
                      <span className="absolute inset-x-0 -top-px bottom-0" />
                      {person.data.name}
                    </a>
                  </p>
                  <p className="mt-1 flex text-xs leading-5 text-gray-500">
                    <a
                      href={`mailto:${person.email}`}
                      className="relative truncate hover:underline"
                    >
                      {person.data.email}
                    </a>
                  </p>
                </div>
              </div>
              <div className="flex shrink-0 items-center gap-x-4">
                <div className="hidden sm:flex sm:flex-col sm:items-end">
                  <p className="text-sm leading-6 text-gray-900">
                    Phone : {person.data.phone}
                  </p>

                  <div className="mt-1 flex items-center gap-x-1.5">
                    <p className="text-xs leading-5 text-gray-500">Online</p>
                  </div>
                  <div className="mt-1 flex items-center gap-x-1.5">
                    <a
                      role="button"
                      className="text-sm font-bold cursor-pointer leading-5 text-sky-500"
                    >
                      Reply
                    </a>
                  </div>
                </div>
                <ChevronRightIcon
                  className="h-5 w-5 flex-none text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </li>
          ))}
      </ul>
    </>
  );
}
