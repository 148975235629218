import Roles from "../Enum/UserRoles";
import { ApiClient } from "./api";

export class User {
  constructor() {
    this.apiClient = new ApiClient();
  }

  async postUser(datas) {
    try {
      const { data } = await this.apiClient.post("user", datas);

      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async updateUser(datas) {
    try {
      const { data } = await this.apiClient.post(
        "user/update/information",
        datas
      );

      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async adminUpdateUser(id, datas) {
    try {
      const { data } = await this.apiClient.put(
        `user/update/from/admin/${id}`,
        datas
      );

      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async getIp() {
    try {
      const { ip } = await this.apiClient.getPublic(
        "https://api.ipify.org/?format=json"
      );

      return ip;
    } catch (error) {
      console.error(error);
    }
  }
  async postBankAccount(datas) {
    try {
      const { data } = await this.apiClient.post(
        "stripe/external_account",
        datas
      );

      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async checkEmail(email) {
    try {
      const { data } = await this.apiClient.get(
        `user/check-if-exists/${email}`
      );

      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async contact(params) {
    try {
      const { data } = await this.apiClient.post(`contact`, params);

      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async allContacts(page, limit) {
    try {
      const { data } = await this.apiClient.get(
        `contact?page=${page}&limit=${limit}`
      );

      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async updateContact(id, params) {
    try {
      const { data } = await this.apiClient.put(`contact/${id}`, params);

      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async postDocumentData(datas, document) {
    try {
      const { data } = await this.apiClient.postWithDocument(
        "user/put-document-id",
        datas,
        document
      );

      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async connectUser(params) {
    try {
      const { data } = await this.apiClient.post("login", params);
      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async connectSubUser(params) {
    try {
      const { data } = await this.apiClient.post("login/sub-user", params);
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async resetPassword(email) {
    try {
      const { data } = await this.apiClient.post(
        `user/reset-password/${email}`
      );
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async getAllUser(page = 1, limit = 10, query = "") {
    try {
      const { data } = await this.apiClient.get(
        `user/all/users?page=${page}&limit=${limit}&search=${query}`
      );
      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async validateEmailAddress(email) {
    try {
      const { data } = await this.apiClient.get(
        `user/admin/manually/verify-email?email=${email}`
      );
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async getStats() {
    try {
      const { data } = await this.apiClient.get(`user/make/stats`);
      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async getCount() {
    try {
      const { data } = await this.apiClient.get(`contact/make/count`);
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async getOneUser(id) {
    try {
      const { data } = await this.apiClient.get(`user/${id}`);
      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async createSubUser(params) {
    try {
      const { data } = await this.apiClient.post(`company_member`, params);
      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async updateSubUser(id, params) {
    try {
      const { data } = await this.apiClient.put(`company_member/${id}`, params);
      return data;
    } catch (error) {
      console.error(error);
    }
  }
}
