import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Conversation } from "../services/conversation";
import { Jobs } from "../services/job";
import socket from "./socket";
import { getUser } from "./users";

const conversation = new Conversation();
const application = new Jobs();

export const fetchConversation = createAsyncThunk(
  "conversation/fetchConversation",
  async () => {
    return await conversation.getConversation();
  }
);
export const fetchConversationToGetAdmin = createAsyncThunk(
  "conversation/fetchConversationToGetAdmin",
  async () => {
    return await conversation.getConversation("Fs");
  }
);

export const fetchCountMessage = createAsyncThunk(
  "conversation/fetchCountMessage",
  async () => {
    return await conversation.getCountMessage();
  }
);

export const fetchNotification = createAsyncThunk(
  "conversation/fetchNotification",
  async () => {
    return await conversation.getNotifications();
  }
);
export const updateNotification = createAsyncThunk(
  "conversation/updateNotification",
  async ({ id, params = {} }) => {
    await conversation.updateNotification(id, params);

    const notifs = await conversation.getNotifications();

    return notifs;
  }
);

export const updateApplication = createAsyncThunk(
  "application/updateApplication",
  async ({ id, params = {} }, thunkAPI) => {
    await application.updateApplication(id, params);

    const notifs = await conversation.getNotifications();

    return notifs;
  }
);

export const logNotificationPage = createAsyncThunk(
  "conversation/setNotificationLog",
  async () => {
    return await conversation.setLogNotification();
  }
);
export const fetchNotificationCount = createAsyncThunk(
  "conversation/fetchNotificationCount",
  async () => {
    return await conversation.getCountNotification();
  }
);

export const fetchMessages = createAsyncThunk(
  "conversation/fetchMessages",
  async (cnvId) => {
    return await conversation.getMessages(cnvId);
  }
);
export const fetchMessagesAdmin = createAsyncThunk(
  "conversation/fetchMessagesAdmin",
  async (_, thunkApi) => {
    const state = thunkApi.getState();

    const { userToSend } = state.conversationSlice;
    const cv = await conversation.getMessagesAdmin(
      userToSend.id,
      userToSend.role
    );

    thunkApi.dispatch(getUser());

    return cv;
  }
);

export const sendMessage = createAsyncThunk(
  "conversation/sendMessage",
  async ({ cnvId, message }, { getState, dispatch }) => {
    await conversation.sendMessage({ convId: cnvId, message });

    // Récupérer la conversation et les messages mis à jour

    if (getState().conversationSlice.tabConvSelected === "Fs") {
      dispatch(fetchConversationToGetAdmin());
    }
    const updatedConv = await conversation.getConversation();
    const updatedMessages = await conversation.getMessages(cnvId);

    return { updatedConv, updatedMessages };
  }
);
export const adminSendMessage = createAsyncThunk(
  "conversation/adminSendMessage",
  async (_, thunkApi) => {
    const state = thunkApi.getState();

    const { userToSend, content } = state.conversationSlice;

    await conversation.adminSendMessage({
      userToSend,
      content,
    });

    return await conversation.getMessagesAdmin(userToSend.id, userToSend.role);
  }
);

export const updateRead = createAsyncThunk(
  "conversation/updateRead",
  async (convId) => {
    await conversation.updateRead(convId);

    const updatedConv = await conversation.getConversation();
    const updatedMessages = await conversation.getMessages(convId);
    const countMsg = await conversation.getCountMessage();
    return { updatedConv, updatedMessages, countMsg };
  }
);

export const conversationSlice = createSlice({
  name: "conversation",
  initialState: {
    conv: [],
    convAdmin: [],
    messages: null,
    loading: false,
    convId: null,
    detail: false,
    sendTo: null,
    notifications: null,
    notifOpen: false,
    countNotification: null,
    countMessages: null,
    userToSend: { id: null, role: null },
    content: null,
    userToSendSelected: false,
    tabConvSelected: "G",
  },
  reducers: {
    resetMessage: (state) => {
      state.messages = null;
      state.content = null;
    },
    setDetail: (state) => {
      state.detail = !state.detail;
    },
    setConvId: (state, action) => {
      state.convId = action.payload;
    },
    setSendTo: (state, action) => {
      state.sendTo = action.payload;
    },
    setUserToSend: (state, action) => {
      state.userToSend = action.payload;
      state.userToSendSelected = true;
    },
    setNotifOpen: (state) => {
      console.warn(state.notifOpen);
      state.notifOpen = !state.notifOpen;
    },
    setContent: (state, action) => {
      state.content = action.payload;
    },
    setSelectUserToSend: (state, action) => {
      state.userToSendSelected = action.payload;
    },
    setTabConvSelected: (state, action) => {
      state.tabConvSelected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConversation.fulfilled, (state, action) => {
        state.conv = action.payload;
      })
      .addCase(fetchConversationToGetAdmin.fulfilled, (state, action) => {
        state.convAdmin = action.payload;
      })

      .addCase(fetchMessages.fulfilled, (state, action) => {
        state.messages = action.payload;
      })
      .addCase(fetchMessagesAdmin.fulfilled, (state, action) => {
        state.messages = action.payload;
        if (action.payload && action.payload[0]) {
          state.convId = action.payload[0].conversationId;
        }
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        state.loading = false;
        const { updatedConv, updatedMessages } = action.payload;

        // Mettre à jour la conversation et les messages dans l'état
        state.conv = updatedConv;
        state.messages = updatedMessages;

        socket.emit("message", {
          targetUserID: state.userToSend,
          type:
            state.tabConvSelected === "Fs"
              ? "new-message-to-admin"
              : "new-message",
          message: {
            data: state.convId,
            content: "You've received a new messages !",
          },
        });
      })
      .addCase(adminSendMessage.fulfilled, (state, action) => {
        state.loading = false;
        state.messages = action.payload;
        state.content = "";

        socket.emit("message", {
          targetUserID: state.userToSend.idTo,
          type: "new-message",
          message: {
            data: state.convId,
            content: "You've received a new messages !",
          },
        });
      })

      .addCase(sendMessage.pending, (state) => {
        state.loading = true;
      })
      .addCase(adminSendMessage.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchNotification.fulfilled, (state, action) => {
        state.notifications = action.payload;
      })
      .addCase(fetchNotificationCount.fulfilled, (state, action) => {
        state.countNotification = action.payload ? action.payload : 0;
      })

      .addCase(logNotificationPage.fulfilled, (state, action) => {
        state.countNotification = action.payload?.error
          ? 0
          : state.countNotification;
      })
      .addCase(updateNotification.fulfilled, (state, action) => {
        state.notifications = action.payload;
      })

      .addCase(updateApplication.fulfilled, (state, action) => {
        state.notifications = action.payload;

        socket.emit("message", {
          targetUserID: state.userToSend,
          type: "new-notification",
          message: {
            data: state.convId,
            content: "You've received a new notification !",
          },
        });
      })
      .addCase(fetchCountMessage.fulfilled, (state, action) => {
        state.countMessages = action.payload ? action.payload : 0;
      })

      .addCase(updateRead.fulfilled, (state, action) => {
        const { updatedConv, updatedMessages, countMsg } = action.payload;

        // Mettre à jour la conversation et les messages dans l'état

        state.conv = updatedConv;
        state.messages = updatedMessages;
        state.countMessages = countMsg ? countMsg : 0;
      });

    // Gérer d'autres cas si nécessaire
  },
});

export const {
  resetMessage,
  setContent,
  setUserToSend,
  setSelectUserToSend,
  setTabConvSelected,
} = conversationSlice.actions;

export default conversationSlice;
