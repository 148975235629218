import React, { useState } from "react";
import useCandidate from "../../../hook/candidate";

import Account from "./Account";
import Notifications from "./Notifications";
import Finance from "./Finance";
import useCompany from "../../../hook/company";
import { useDispatch, useSelector } from "react-redux";
import { setStage } from "../../../redux/navigation";
import NewMember from "./new-member";
import useShift from "../../../hook/shift";

function Profile() {
  const { company } = useCompany();
  const dispatch = useDispatch();
  useShift();

  const { stage } = useSelector((state) => state.NavigationSlice);

  const secondaryNavigation = [
    { name: "Account", href: 0, current: stage === 0 },
    { name: "Notifications", href: 1, current: stage === 1 },
    { name: "Finance", href: 2, current: stage === 2 },
    { name: "Teams", href: 3, current: stage === 3 },
  ];

  return (
    <main className="md:max-w-7xl w-sm overflow-hidden">
      <header className="border-b border-gray-300">
        {/* Secondary navigation */}
        <nav className="flex overflow-x-auto py-4">
          <ul
            role="list"
            className="flex min-w-full flex-none gap-x-6 px-4 text-sm font-semibold leading-6 cursor-pointer text-gray-600 sm:px-6 lg:px-8"
          >
            {secondaryNavigation.map((item) => (
              <li key={item.name}>
                <a
                  onClick={() => dispatch(setStage(item.href))}
                  className={
                    item.current ? "text-sky-400 relative" : "relative"
                  }
                >
                  {company &&
                    company.user &&
                    !company.user.stripe &&
                    item.href === 2 && (
                      <span class="absolute top-0 -right-1 inline-flex items-center w-3.5 h-3.5 rounded-full border-2 border-white text-xs font-medium transform -translate-y-1/2 translate-x-1/2 bg-red-500 text-white">
                        <span class="sr-only">Badge value</span>
                      </span>
                    )}
                  {company &&
                    company.user &&
                    company.user.stripe &&
                    company.user.stripe.requirements.currently_due.length > 0 &&
                    item.href === 2 && (
                      <span class="absolute top-0 -right-1 inline-flex items-center w-3.5 h-3.5 rounded-full border-2 border-white text-xs font-medium transform -translate-y-1/2 translate-x-1/2 bg-red-500 text-white">
                        <span class="sr-only">Badge value</span>
                      </span>
                    )}

                  {item.name}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </header>

      {/* Settings forms */}

      {company && stage === 0 && <Account />}
      {company && stage === 1 && <Notifications />}
      {company && stage === 2 && <Finance />}
      {company && stage === 3 && <NewMember />}
    </main>
  );
}

export default Profile;
