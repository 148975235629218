import React from "react";
import { useDispatch, useSelector } from "react-redux";
import useGeolocation from "../../hook/geolocation";
import { baseUrl } from "../../utils";
import Application from "../../Enum/Applicatition";
import { Link } from "react-router-dom";

export function InstantJobLoading() {
  return (
    <div className="bg-white  shadow-xl border   shadow-gray-100 min-w-[fit-content]  flex flex-col sm:flex-row gap-3 sm:items-center  justify-between px-5 py-4 rounded-md">
      <div>
        <div
          className="inline-flex items-center
        "
        >
          <div className="w-6 h-6 object-scale-down rounded-sm bg-slate-200 animate-pulse me-2 "></div>

          <h3
            className="font-bold text-sm bg-slate-200 animate-pulse
           mt-px text-transparent rounded-lg"
          >
            loading.............
          </h3>
        </div>

        <div className="flex items-center gap-3 mt-2 border-">
          <span className=" bg-slate-200 animate-pulse text-transparent  rounded-full whitespace-nowrap px-3 py-1 text-xs font-bold">
            CODE 00/h
          </span>

          <span className="bg-slate-200 animate-pulse text-transparent rounded-full px-3 py-1 text-xs font-bold">
            Today
          </span>

          <span className="text-transparent animate-pulse bg-slate-200 rounded-full text-sm flex gap-1 items-center font-bold">
            {" "}
            <svg
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
            00 Km away
          </span>
        </div>
      </div>
      <div>
        <button className="bg-slate-200 text-transparent animate-pulse font-medium px-4 whitespace-nowrap truncate xs:min-w-full py-2 rounded-md flex gap-1 text-sm xs:text-center items-center">
          Quick apply
        </button>
      </div>
    </div>
  );
}

function InstantJob({ item, key }) {
  const { open } = useSelector((state) => state.ApplicationSlice);
  const locateme = useGeolocation();
  const dispatch = useDispatch();
  const { candidate, onLoad } = useSelector((state) => state.candidateSlice);

  const handleApply = () => {
    if (!candidate.user.visible) {
      dispatch({
        type: "candidateSlice/setOpen",
      });
    } else {
      dispatch({
        type: "ApplicationSlice/setOpen",
        payload: !open,
      });

      dispatch({
        type: "ApplicationSlice/setHr",
        payload: {
          hourlyRate: parseFloat(item.hourlyRate).toFixed(2),
          currency: item.company.currency.code,
          symbol: item.company.currency.symbol,
          name: item.jobName,
          jobId: item.id,
          date: item.startDate,
          time: item.startHour,
          logo: item.company.logo,
          userId: item.company.userId,
        },
      });
    }
  };

  return (
    <div
      key={key}
      className="bg-white  shadow-xl border   shadow-gray-100 min-w-[fit-content] md:flex-nowrap flex-wrap flex flex-col sm:flex-row gap-3 sm:items-center  justify-between px-5 py-4 rounded-md"
    >
      <div>
        <div
          className="inline-flex items-center space-x-2
        "
        >
          {!item.company.logo && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"
              />
            </svg>
          )}

          {item.company.logo && (
            <img
              src={[baseUrl, item.company.logo].join("")}
              alt=""
              className="w-6 h-6 object-scale-down   "
              srcset=""
            />
          )}
          <Link
            to={`/jobs/${item.id}`}
            className="font-bold text-sm 
           mt-px text-indigo-700 hover:underline "
          >
            {item.jobName}
          </Link>
        </div>

        <div className="flex items-center gap-3 mt-2 border-">
          <span className="bg-teal-100 text-sky-700 rounded-full whitespace-nowrap px-3 py-1 text-xs font-bold">
            {item.hourlyRate} {item.company.currency.code}/h
          </span>
          {item.type !== "DEFAULT" && (
            <span className="bg-purple-600 text-white rounded-full px-3 py-1 text-xs font-bold">
              {item.type}
            </span>
          )}

          <span className="text-slate-600 text-sm flex gap-1 items-center font-bold">
            {" "}
            <svg
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
            {item.distance}
          </span>
        </div>
      </div>
      <div>
        {!onLoad && (
          <button
            onClick={handleApply}
            disabled={candidate.applications.some((e) => e.job === item.id)}
            className={` ${
              candidate.applications.some(
                (e) => e.job === item.id && e.status === Application.PENDING
              )
                ? "bg-gray-400 animate-pulse"
                : candidate.applications.some(
                    (e) =>
                      e.job === item.id && e.status === Application.ACCEPTED
                  )
                ? "bg-teal-600"
                : candidate.applications.some(
                    (e) =>
                      e.job === item.id && e.status === Application.REJECTED
                  )
                ? "bg-red-700"
                : "bg-sky-500"
            } text-white font-medium px-4 whitespace-nowrap truncate min-w-full py-2 rounded-md flex gap-1 text-sm xs:text-center items-center`}
          >
            <span className="text-center w-full">
              {`${
                candidate.applications.some((e) => e.job === item.id)
                  ? candidate.applications.some(
                      (e) =>
                        e.job === item.id && e.status === Application.PENDING
                    )
                    ? "Pending"
                    : candidate.applications.some(
                        (e) =>
                          e.job === item.id && e.status === Application.ACCEPTED
                      )
                    ? "Accepted"
                    : "Rejected"
                  : "Apply now"
              }`}
            </span>
          </button>
        )}
      </div>
    </div>
  );
}

export default InstantJob;
