import { ApiClient } from "./api";

export class Stripe {
  constructor() {
    this.apiClient = new ApiClient();
  }

  async getInvoices(
    page,
    limit,
    invoice_id,
    customer_id,
    lastInvoiceId,
    firstInvoiceId,
    prev
  ) {
    try {
      console.warn(prev);

      const { data } = await this.apiClient.get(
        `stripe/list/invoices?page=${page}&limit=${limit}&invoice_id=${invoice_id}&customer_id=${customer_id}&lastInvoiceId=${lastInvoiceId}&firstInvoiceId=${firstInvoiceId}&prevPage=${prev}`
      );

      return data;
    } catch (error) {
      console.error(error);
    }
  }
}
