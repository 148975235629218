import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { baseUrl } from "../../../utils";
import {
  setAccountNumber,
  setCountry,
  updateBankAccount,
} from "../../../redux/candidate";
import TableFinance from "../Finance/table";

function Stats() {
  const { candidate, onLoad } = useSelector((state) => state.candidateSlice);

  return (
    <div className="max-w-[85rem] py-6 px-4 sm:px-6 lg:px-8 mx-auto">
      <div className="grid gap-6 grid-cols-2 sm:gap-12 lg:grid-cols-3 lg:gap-8">
        <div className="flex flex-col items-center">
          <h4 className="text-sm md:text-xl font-semibold text-gray-800 dark:text-gray-200">
            Total Hours
          </h4>
          <p className="mt-2 sm:mt-3 text-2xl text-center sm:text-5xl font-bold text-blue-500">
            {candidate.finance.totalHours} H
          </p>
          <p className="mt-1 text-sm text-gray-500 text-center">
            Total hours of all shift
          </p>
        </div>

        <div className="flex flex-col items-center">
          <h4 className="text-sm md:text-xl  font-semibold text-gray-800 dark:text-gray-200">
            Total Earn
          </h4>
          <p className="mt-2 sm:mt-3 text-2xl text-center sm:text-5xl font-bold text-green-500">
            {[
              candidate.finance.totalEarn,
              candidate?.hourlyRate?.currency?.code,
            ].join(" ")}
          </p>
          <p className="mt-1 text-sm text-center text-gray-500">
            Total earn as Temper{" "}
          </p>
        </div>

        <div className="flex flex-col items-center col-span-2 lg:col-span-1">
          <h4 className="text-sm md:text-xl  font-semibold text-gray-800 dark:text-gray-200">
            No show
          </h4>
          <p className="mt-2 sm:mt-3 text-2xl sm:text-5xl font-bold text-red-500">
            {candidate.finance.noShowCount}
          </p>
          <p className="mt-1 text-gray-500 text-sm text-center">
            Missing shifts
          </p>
        </div>
      </div>
    </div>
  );
}

function Finance() {
  const { candidate, onLoad, pattern, exemple } = useSelector(
    (state) => state.candidateSlice
  );
  const dispatch = useDispatch();

  function handleSubmit(e) {
    e.preventDefault();

    dispatch(updateBankAccount());
  }

  return (
    <div >
      <Stats />
      <hr />
      <div className="p-5">
        <h1 className="text-lg md:text-2xl font-semibold text-center">
          Bank account information
        </h1>
      </div>
      <hr />
      <div className="p-4">
        {candidate.user &&
          candidate.user.stripe &&
          candidate.user.stripe.requirements.currently_due.length > 0 && (
            <a
              type="button"
              href={[
                baseUrl,
                `/api/stripe/create-account/${candidate.user.id}`,
              ].join("")}
              target="_top"
              className="p-5 flex justify-center   border-fuchsia-600 items-center flex-col col-span-full rounded-lg border-dashed border-2"
            >
              <span className="font-medium text-center ">
                We need you accept terms of Stripe Service Agreement to let you
                handle your finance with ease. <br />{" "}
                <p className="text-xs leading-3 text-sky-600 md:text-sm">
                  require : IBAN (1-3 min max)
                </p>{" "}
              </span>
              <p className="text-sky-600 text-lg font-medium animate-pulse mt-4">
                Click here to update it !
              </p>
            </a>
          )}
        {candidate.user &&
          candidate.user.stripe &&
          candidate.user.stripe.requirements.currently_due.length === 0 && (
            <div className="inline-flex items-center justify-center w-full space-x-5">
              <span
                onClick={() =>
                  window.location.replace(
                    [
                      baseUrl,
                      `/api/stripe/create-account/${candidate.user.id}`,
                    ].join("")
                  )
                }
                className="text-blue-500   text-xs text-center md:text-sm cursor-pointer font-semibold hover:text-blue-300 active:text-sky-500"
              >
                Update Payment details{" "}
              </span>
              <span className="text-blue-500   text-xs text-center md:text-sm  cursor-pointer font-semibold hover:text-blue-300 active:text-sky-500">
                Go on my Stripe dashbord
              </span>
            </div>
          )}

        <div className="space-y-6 border rounded-lg px-4 lg:col-span-9 lg:px-0 mt-3">
          <section aria-labelledby="payment-details-heading">
            <form onSubmit={handleSubmit}>
              <div className=" sm:overflow-hidden sm:rounded-md">
                <div className=" px-4 py-6 sm:p-6">
                  <div>
                    <h2
                      id="payment-details-heading"
                      className="text-sm md:text-lg font-medium leading-6 text-gray-900"
                    >
                      Payment details
                    </h2>
                    <p className="mt-1 text-xs md:text-sm text-gray-500">
                      Update your bank account information. Please note that
                      updating your location could affect your tax rates.
                    </p>
                  </div>

                  <div className="mt-6 grid grid-cols-6 gap-y-3  md:gap-6">
                    <div className="col-span-full sm:col-span-2">
                      <label
                        htmlFor="first-name"
                        className="block text-xs md:text-sm font-medium leading-6 text-gray-900"
                      >
                        First name
                      </label>
                      <input
                        type="text"
                        name="first-name"
                        id="first-name"
                        defaultValue={candidate.firstName}
                        autoComplete="cc-given-name"
                        className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 text-xs md:text-sm sm:leading-6"
                      />
                    </div>

                    <div className="col-span-full sm:col-span-2">
                      <label
                        htmlFor="last-name"
                        className="block text-xs md:text-sm font-medium leading-6 text-gray-900"
                      >
                        Last name
                      </label>
                      <input
                        type="text"
                        name="last-name"
                        id="last-name"
                        defaultValue={candidate.lastName}
                        autoComplete="cc-family-name"
                        className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 text-xs md:text-sm sm:leading-6"
                      />
                    </div>
                    {candidate.user.stripe?.external_accounts.data[0] && (
                      <div className="col-span-4 sm:col-span-3">
                        <label
                          htmlFor="last-name"
                          className="block text-xs md:text-sm font-medium leading-6 text-gray-900"
                        >
                          Bank name
                        </label>
                        <input
                          type="text"
                          name="bankname"
                          id="bank-name"
                          defaultValue={`${candidate.user.stripe?.external_accounts.data[0]?.bank_name}`}
                          autoComplete="bank-name"
                          className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 text-xs md:text-sm sm:leading-6"
                        />
                      </div>
                    )}

                    {candidate.user.stripe?.external_accounts.data[0] && (
                      <div className="col-span-full sm:col-span-4">
                        <label
                          htmlFor="expiration-date"
                          className="block text-xs md:text-sm font-medium leading-6 text-gray-900"
                        >
                          Account number (IBAN)
                          <span className="text-red-600 after:content-['*']"></span>
                        </label>
                        <input
                          type="text"
                          name="expiration-date"
                          onChange={(e) =>
                            dispatch(setAccountNumber(e.target.value))
                          }
                          id="expiration-date"
                          autoComplete="cc-exp"
                          defaultValue={`**** **** **** **** **** ${candidate.user.stripe?.external_accounts.data[0]?.last4}`}
                          className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 text-xs md:text-sm sm:leading-6"
                          placeholder={exemple}
                        />
                      </div>
                    )}
                    {!candidate.user.stripe?.external_accounts.data[0] && (
                      <div className="col-span-full sm:col-span-4">
                        <label
                          htmlFor="expiration-date"
                          className="block text-xs md:text-sm font-medium leading-6 text-gray-900"
                        >
                          Account number (IBAN)
                          <span className="text-red-600 after:content-['*']"></span>
                        </label>
                        <input
                          type="text"
                          name="expiration-date"
                          onChange={(e) =>
                            dispatch(setAccountNumber(e.target.value))
                          }
                          id="expiration-date"
                          autoComplete="cc-exp"
                          className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 text-xs md:text-sm sm:leading-6"
                          placeholder={exemple}
                        />
                      </div>
                    )}

                    {candidate.user.stripe?.external_accounts.data[0] && (
                      <div className="col-span-4 sm:col-span-2">
                        <label
                          htmlFor="last-name"
                          className="block text-xs md:text-sm font-medium leading-6 text-gray-900"
                        >
                          Country{" "}
                          <span className="text-red-600 after:content-['*']"></span>
                        </label>
                        <input
                          type="text"
                          name="bankname"
                          id="bank-name"
                          defaultValue={`${candidate.user.stripe?.external_accounts.data[0]?.country}`}
                          autoComplete="bank-name"
                          className="mt-2 block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-900 text-xs md:text-sm sm:leading-6"
                        />
                      </div>
                    )}

                    {!candidate.user.stripe?.external_accounts.data[0] && (
                      <div className="col-span-4 sm:col-span-3">
                        <label
                          htmlFor="country"
                          className="block text-xs md:text-sm font-medium leading-6 text-gray-900"
                        >
                          Country
                          <span className="text-red-600 after:content-['*']"></span>
                        </label>
                        <select
                          id="country"
                          name="country"
                          onChange={(e) => dispatch(setCountry(e.target.value))}
                          autoComplete="country-name"
                          className="mt-2 block w-full rounded-md border-0 bg-white px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-gray-900 text-xs md:text-sm sm:leading-6"
                        >
                          <option
                            value={JSON.stringify({
                              country: "GB",
                              currency: "gbp",
                              pattern:
                                "^(?:(?:IT|SM)d{2}[A-Z]d{22}|CYd{2}[A-Z]d{23}|NLd{2}[A-Z]{4}d{10}|LVd{2}[A-Z]{4}d{13}|(?:BG|BH|GB|IE)d{2}[A-Z]{4}d{14}|GId{2}[A-Z]{4}d{15}|ROd{2}[A-Z]{4}d{16}|KWd{2}[A-Z]{4}d{22}|MTd{2}[A-Z]{4}d{23}|NOd{13}|(?:DK|FI|GL|FO)d{16}|MKd{17}|(?:AT|EE|KZ|LU|XK)d{18}|(?:BA|HR|LI|CH|CR)d{19}|(?:GE|DE|LT|ME|RS)d{20}|ILd{21}|(?:AD|CZ|ES|MD|SA)d{22}|PTd{23}|(?:BE|IS)d{24}|(?:FR|MR|MC)d{25}|(?:AL|DO|LB|PL)d{26}|(?:AZ|HU)d{27}|(?:GR|MU)d{28})$",
                              exemple: "GB29NWBK60161331926819",
                            })}
                          >
                            United Kingdom
                          </option>
                          <option
                            value={JSON.stringify({
                              country: "AE",
                              currency: "aed",
                              pattern:
                                "^(?:(?:IT|SM)d{2}[A-Z]d{22}|CYd{2}[A-Z]d{23}|NLd{2}[A-Z]{4}d{10}|LVd{2}[A-Z]{4}d{13}|(?:BG|BH|GB|IE)d{2}[A-Z]{4}d{14}|GId{2}[A-Z]{4}d{15}|ROd{2}[A-Z]{4}d{16}|KWd{2}[A-Z]{4}d{22}|MTd{2}[A-Z]{4}d{23}|NOd{13}|(?:DK|FI|GL|FO)d{16}|MKd{17}|(?:AT|EE|KZ|LU|XK)d{18}|(?:BA|HR|LI|CH|CR)d{19}|(?:GE|DE|LT|ME|RS)d{20}|ILd{21}|(?:AD|CZ|ES|MD|SA)d{22}|PTd{23}|(?:BE|IS)d{24}|(?:FR|MR|MC)d{25}|(?:AL|DO|LB|PL)d{26}|(?:AZ|HU)d{27}|(?:GR|MU)d{28})$",
                              exemple: "AE070331234567890123456",
                            })}
                          >
                            United arab emirates
                          </option>
                          <option
                            value={JSON.stringify({
                              country: "SA",
                              currency: "sar",
                              pattern:
                                "^(?:(?:IT|SM)d{2}[A-Z]d{22}|CYd{2}[A-Z]d{23}|NLd{2}[A-Z]{4}d{10}|LVd{2}[A-Z]{4}d{13}|(?:BG|BH|GB|IE)d{2}[A-Z]{4}d{14}|GId{2}[A-Z]{4}d{15}|ROd{2}[A-Z]{4}d{16}|KWd{2}[A-Z]{4}d{22}|MTd{2}[A-Z]{4}d{23}|NOd{13}|(?:DK|FI|GL|FO)d{16}|MKd{17}|(?:AT|EE|KZ|LU|XK)d{18}|(?:BA|HR|LI|CH|CR)d{19}|(?:GE|DE|LT|ME|RS)d{20}|ILd{21}|(?:AD|CZ|ES|MD|SA)d{22}|PTd{23}|(?:BE|IS)d{24}|(?:FR|MR|MC)d{25}|(?:AL|DO|LB|PL)d{26}|(?:AZ|HU)d{27}|(?:GR|MU)d{28})$",
                              exemple: "SA0380000000608010167519",
                            })}
                          >
                            Saudi arabia
                          </option>
                        </select>
                      </div>
                    )}
                  </div>
                </div>
                {!candidate.user.stripe?.external_accounts.data[0] && (
                  <div className=" px-4 flex justify-center py-3 text-right sm:px-6">
                    <button
                      type="submit"
                      className="inline-flex justify-center w-52  rounded-md bg-sky-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                    >
                      Save
                    </button>
                  </div>
                )}
              </div>
            </form>
          </section>
        </div>
      </div>
      <br />
      <br />
      <TableFinance />
    </div>
  );
}

export default Finance;

/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
