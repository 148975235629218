import { useDispatch, useSelector } from "react-redux";
import { fetchAllInvoices, setPage } from "../redux/stripe";
import { useCallback, useEffect } from "react";

export function useStripeInvoices() {
  const dispatch = useDispatch();
  const { page, limit, invoice_id, customer_id } = useSelector(
    (state) => state.stripeSlice
  );

  const fetchData = useCallback(() => {
    dispatch(fetchAllInvoices());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setPage(1));
  }, [limit, invoice_id, customer_id]);

  useEffect(() => {
    fetchData();
  }, [fetchData, page, limit, invoice_id, customer_id]);
}
