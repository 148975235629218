import React, { useState } from "react";
import UserList from "./List-user";
import { Border } from "../../../components/utils/Border";
import { PageIntro } from "../../../components/utils/PageIntro";
import { setQuery } from "../../../redux/users";
import { useDispatch, useSelector } from "react-redux";
import { moment } from "moment";
import Roles from "../../../Enum/UserRoles";
import { baseUrl } from "../../../utils";
import GoBack from "../../../components/utils/goBack";
import {
  PlusIcon,
  PaperAirplaneIcon,
  ArrowDownLeftIcon,
} from "@heroicons/react/20/solid";
import { adminSendMessage, setContent } from "../../../redux/conversation";
import { useConversationAdmin } from "../../../hook/conversation";
import Conversation from "./conversation";
function ChatBubble({
  incoming = false,
  outgoing = false,
  item,
  image = null,
  role,
}) {
  const { candidate } = useSelector((state) => state.candidateSlice);
  const { company } = useSelector((state) => state.companySlice);

  return incoming ? (
    <div className="chat chat-start">
      <div className="chat-image avatar">
        <div className="w-10 rounded-full">
          <img src={[baseUrl, image].join("")} />
        </div>
      </div>

      <div className="chat-bubble bg-sky-500 text-white">{item.message}</div>
      <div className="chat-footer opacity-50 text-xs">
        {moment.utc(item.creatAt).local().fromNow()}
      </div>
    </div>
  ) : (
    <div className="chat chat-end ">
      <div className="chat-image avatar">
        <div className="w-10 rounded-full">
          {role === Roles.candidate && (
            <img
              src={[baseUrl, candidate.candidateProfile.profile.image].join("")}
            />
          )}
          {role === Roles.company && (
            <img src={[baseUrl, company.logo].join("")} />
          )}
        </div>
      </div>

      <div className="chat-bubble bg-base-1 text-white">{item.message}</div>
      <div className="chat-footer  opacity-50 text-xs">
        {" "}
        {moment.utc(item.creatAt).local().fromNow()}
      </div>
    </div>
  );
}

function Messages() {
  const dispatch = useDispatch();
  const { userToSendSelected } = useSelector(
    (state) => state.conversationSlice
  );

  return (
    <>
      <h1 className="font-bold">Messages</h1>
      <p className="text-sm">
        Contact user to inform them about their situation
      </p>
      <div className="grid grid-cols-12 gap-x-0">
        <div className="col-span-5 flex flex-row">
          <div className="flex flex-col w-full">
            <div className="ring-1 my-8 ring-sky-200 rounded-lg shadow relative">
              <input
                type="text"
                onChange={(e) => dispatch(setQuery(e.currentTarget.value))}
                className=" w-full relative py-1 px-4 border-transparent rounded-lg  pr-9"
                placeholder="Search user,company,candidate..."
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5 absolute  bottom-1.5  right-3 cursor-pointer"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                />
              </svg>
            </div>
            <UserList />
          </div>

          <Border position="left" className={"mx-5"} />
        </div>

        {userToSendSelected && <Conversation />}

        {!userToSendSelected && (
          <div className=" col-span-7 flex flex-1 flex-col justify-center items-center animate-pulse">
            <h1>Select a user to launch a conversation</h1>
          </div>
        )}
      </div>
    </>
  );
}

export default Messages;
