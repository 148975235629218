import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { User } from "../services/user";
import { setClose, setOpenSideEdit } from "./teams";
import {
  setEmail,
  setFirstName,
  setLastName,
  setLocation,
  setPhone,
} from "./registration";
import { fetchCompany } from "./company";
import { setShow, setShowWithTimeout } from "./toast";

const userApi = new User();

export const getUser = createAsyncThunk(
  "UserSlice/getUsers",
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState();
    const { page, limit, query } = state.UserSlice;

    return await userApi.getAllUser(page, limit, query);
  }
);
export const adminValidateUserEmail = createAsyncThunk(
  "UserSlice/adminValidateUserEmail",
  async ({ email }, thunkAPI) => {
    await userApi.validateEmailAddress(email);

    const state = thunkAPI.getState();
    const { id } = state.UserSlice;
    thunkAPI.dispatch(setShowWithTimeout());

    return await userApi.getOneUser(id);
  }
);

export const getStats = createAsyncThunk(
  "UserSlice/getStats",
  async (arg, thunkAPI) => {
    return await userApi.getStats();
  }
);
export const getCount = createAsyncThunk(
  "UserSlice/getCount",
  async (arg, thunkAPI) => {
    return await userApi.getCount();
  }
);
export const updateUser = createAsyncThunk(
  "UserSlice/updateUser",
  async ({ data }, thunkAPI) => {
    const state = thunkAPI.getState();
    const { id } = state.UserSlice;
    return await userApi.adminUpdateUser(id, data);
  }
);
export const getAllContacts = createAsyncThunk(
  "UserSlice/getContacts",
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState();
    const { page, limit, query, page_contact } = state.UserSlice;
    return await userApi.allContacts(page_contact, limit);
  }
);
export const updateContact = createAsyncThunk(
  "UserSlice/updateContact",
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState();
    const { text, inquirie } = state.UserSlice;
    await userApi.updateContact(inquirie.id, {
      text,
      replied: true,
    });

    const { page, limit, query, page_contact } = state.UserSlice;
    const res1 = await userApi.allContacts(page_contact, limit);
    const res2 = await userApi.getCount();

    return { res1, res2 };
  }
);

export const findOneUser = createAsyncThunk(
  "UserSlice/getOne",
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState();
    const { id } = state.UserSlice;

    return await userApi.getOneUser(id);
  }
);





export const createSubUser = createAsyncThunk(
  "UserSlice/createSubUser",
  async (arg, thunkAPI) => {
    const state = thunkAPI.getState();
    const { firstName, lastName, phone, email, location } =
      state.RegistrationSlice;
    const { role } = state.TeamSlice;
    const { selected, company } = state.companySlice;
    const r = await userApi.createSubUser({
      firstName,
      lastName,
      email,
      role: role.id,
      id: selected.id || company.id,
      companyName: selected.name || company.name,
      extra: {
        location,
        phone,
      },
    });

    thunkAPI.dispatch(setClose());
    thunkAPI.dispatch(setEmail(null));
    thunkAPI.dispatch(setFirstName());
    thunkAPI.dispatch(setLastName());
    thunkAPI.dispatch(setPhone());
    thunkAPI.dispatch(setLocation());
    thunkAPI.dispatch(fetchCompany());

    return r;
  }
);
export const updateSubUser = createAsyncThunk(
  "UserSlice/updateSubUser",
  async ({ id }, thunkAPI) => {
    const state = thunkAPI.getState();
    const { phone, email, location } = state.RegistrationSlice;
    const { role, teamMember } = state.TeamSlice;

    const r = await userApi.updateSubUser(id, {
      role: role.id,
      extra: {
        location: location || teamMember.extra.location,
        phone: phone || teamMember.extra.phone,
      },
    });
    thunkAPI.dispatch(setOpenSideEdit(false));
    thunkAPI.dispatch(fetchCompany());
    return r;
  }
);

export const UserSlice = createSlice({
  name: "UserSlice",
  initialState: {
    id: null,
    users: [],
    contacts: [],
    user: {},
    stats: {},
    pending: false,
    page: 1,
    page_contact: 1,
    limit: 10,
    query: "",
    openModal: false,
    openContact: false,
    inquirie: {},
    text: null,
    count: 0,
  },
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    setPageContact: (state, action) => {
      state.page = action.payload;
    },
    setQuery: (state, action) => {
      if (state.page > 0) {
        state.page = 1;
      }
      state.query = action.payload;
    },
    setCloseModal: (state, action) => {
      state.openModal = false;
    },
    setOpenModal: (state, action) => {
      state.openModal = true;
    },
    setId: (state, action) => {
      state.id = action.payload;
    },
    setOpenContact: (state, action) => {
      state.openContact = true;
    },
    setCloseContact: (state, action) => {
      state.openContact = false;
    },
    setInquirie: (state, action) => {
      state.inquirie = action.payload;
    },
    setText: (state, action) => {
      state.text = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.fulfilled, (state, action) => {
        state.users = action.payload;
      })
      .addCase(findOneUser.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(createSubUser.pending, (state) => {
        state.pending = true;
      })
      .addCase(createSubUser.fulfilled, (state, action) => {
        state.pending = false;
        state.openModal = false;
      })
      .addCase(getStats.fulfilled, (state, action) => {
        state.stats = action.payload;
      })
      .addCase(getAllContacts.fulfilled, (state, action) => {
        state.contacts = action.payload;
      })
      .addCase(updateContact.fulfilled, (state, action) => {
        state.contacts = action.payload.res1;
        state.count = action.payload.res2;
        state.text = null;
        state.openContact = false;
      })

      .addCase(getCount.fulfilled, (state, action) => {
        state.count = action.payload;
      })
      .addCase(adminValidateUserEmail.fulfilled, (state, action) => {
        state.user = action.payload;
      });
  },
});

export const {
  setPage,
  setQuery,
  setOpenModal,
  setCloseModal,
  setId,
  setPageContact,
  setOpenContact,
  setContact,
  setCloseContact,
  setInquirie,
  setText,
  setLimit
} = UserSlice.actions;
