import React, { useEffect, useState } from "react";
import { styleMap } from "../../../utils";
import {
  Circle,
  CircleF,
  GoogleMap,
  Marker,
  MarkerF,
  MarkerClusterer,
  useLoadScript,
} from "@react-google-maps/api";
import { useSelector } from "react-redux";
import useJob from "../../../hook/job";
import useGeolocation from "../../../hook/geolocation";
import { useNavigate } from "react-router-dom";

function Map() {
  const { candidate, onLoad } = useSelector((state) => state.candidateSlice);
  const locateme = useGeolocation();
  const [zoom, setZoom] = useState(12);
  const navigate = useNavigate();

  const { latitude, longitude } = useSelector(
    (state) => state.GeolocationSlice
  );
  const { data, hasMore, fetchData } = useJob({ all: true, getAll: true });

  const { isLoaded } = useLoadScript({
    id: "google-map-script-2",
    googleMapsApiKey: "AIzaSyAAZzq54uXg1aSR-8NSY41uz5-4P5JCWQM",
  });

  return (
    <div className="h-full  w-full">
      {isLoaded && !onLoad && (
        <GoogleMap
          mapContainerClassName="h-full w-full"
          center={{
            lat: latitude,
            lng: longitude,
          }}
          zoom={zoom}
          options={{
            styles: styleMap,
            mapTypeControl: false,
            fullscreenControl: false,
            panControl: false,
          }}
        >
          <MarkerClusterer averageCenter enableRetinaIcons gridSize={60}>
            {(clusterer) =>
              data &&
              data.map((job, i) => (
                <Marker
                  key={i}
                  onClick={() => navigate(`/jobs/${job.id}`)}
                  position={{
                    lat: job.location.lat,
                    lng: job.location.lng,
                  }}
                  label={{
                    text: job.jobName,
                    className:
                      "absolute  bottom-[0.9rem] -right-20 px-1  rounded-md md:text-sm text-xs font-semibold bg-slate-300  ",
                  }}
                  icon={{
                    url: require("../../../assets/img/company.png"),
                    scaledSize: {
                      width: 50,
                      height: 50,
                    },
                  }}
                  clusterer={clusterer}
                />
              ))
            }
          </MarkerClusterer>

          <Marker
            position={{
              lat: latitude,
              lng: longitude,
            }}
            icon={{
              url: require("../../../assets/img/worker.png"),

              scaledSize: {
                width: 50,
                height: 50,
              },
            }}
          />
          {!onLoad && (
            <Circle
              center={{
                lat: latitude,
                lng: longitude,
              }}
              radius={candidate.radius * 1000}
            />
          )}
        </GoogleMap>
      )}
    </div>
  );
}

export default Map;
