import React, { useState, useEffect } from "react";
import { Document, Page } from "react-pdf";
import { baseUrl } from "../../utils";

export function FilePreview({ filePath }) {
  const [fileType, setFileType] = useState("");

  useEffect(() => {
    // Déterminez le type de fichier ici (par exemple, en utilisant l'extension du fichier)
    setFileType(determineFileType(filePath));
  }, [filePath]);

  const determineFileType = (path) => {
    // Logique pour déterminer le type de fichier
    return path.split(".").pop();
  };

  return (
    <div className="flex flex-col items-center justify-center">
      {fileType === "pdf" ? (
        <div className="flex justify-center items-center">
          <iframe
            src={[baseUrl, filePath].join(
              process.env.NODE_ENV === "production"
                ? "/api/uploads/"
                : "/uploads/"
            )}
            title="PDF Viewer"
            className="rounded-lg shadow-lg md:w-[600px] h-96"
          ></iframe>
        </div>
      ) : (
        <img
          src={[baseUrl, filePath].join(
            process.env.NODE_ENV === "production"
              ? "/api/uploads/"
              : "/uploads/"
          )}
          className="rounded-lg shadow-lg shadow-sky-300"
          alt="file preview"
        />
      )}
    </div>
  );
}
