import { ApiClient } from "./api";

export class Admin {
  constructor() {
    this.apiClient = new ApiClient();
  }

  async getAuthAdmin() {
    try {
      const { data } = await this.apiClient.get(`admin/req`);

      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async createAdmin(params) {
    try {
      const { data } = await this.apiClient.post(`admin`, params);

      return data;
    } catch (error) {
      console.error(error);
    }
  }
  async getAdmins(page, limit, query) {
    try {
      const { data } = await this.apiClient.get(
        `admin?page=${page}&limit=${limit}&search=${query}`
      );

      return data;
    } catch (error) {
      console.error(error);
    }
  }
}
