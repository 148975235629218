import { PlusIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useDispatch } from "react-redux";
import { setOpenModal } from "../../redux/users";

function AddNewUser() {
  const dispatch = useDispatch();
  return (
    <div className="relative">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-300" />
      </div>
      <div className="relative flex justify-center">
        <button
          onClick={() => dispatch(setOpenModal())}
          type="button"
          className="inline-flex items-center gap-x-1.5 rounded-full bg-sky-600 px-3 py-1.5 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 "
        >
          <PlusIcon
            className="-ml-1 -mr-0.5 h-5 w-5 text-white"
            aria-hidden="true"
          />
          Add sub user
        </button>
      </div>
    </div>
  );
}

export default AddNewUser;
