import React, { useEffect } from "react";
import OursCompanies from "../../../components/company/our_company";
import { PlusIcon } from "@heroicons/react/24/outline";
import Search from "../../../components/search/search";
import { useDispatch } from "react-redux";
import { setLimit } from "../../../redux/company";
import AddNewUser from "../../../components/utils/addNewUser";

function Companies() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setLimit(10));
  }, []);

  return (
    <div>
      <h1 className="mb-6 font-semibold text-sky-600">Ours companies</h1>
      <Search />
      <AddNewUser />
      <OursCompanies />
    </div>
  );
}

export default Companies;
