import React from "react";
import GoBack from "../../../components/utils/goBack";
import { useDispatch, useSelector } from "react-redux";
import { adminSendMessage, setContent } from "../../../redux/conversation";
import { useConversationAdmin } from "../../../hook/conversation";
import {
  PlusIcon,
  PaperAirplaneIcon,
  ArrowDownLeftIcon,
} from "@heroicons/react/20/solid";
import moment from "moment";

import { baseUrl } from "../../../utils";
import Roles from "../../../Enum/UserRoles";
import { FadeIn, FadeInStagger } from "../../../components/utils/FadeIn";
import { motion } from "framer-motion";

function ChatBubble({ incoming = false, item, image = null }) {
  return incoming ? (
    <div className="chat chat-start">
      <div className="chat-image avatar">
        <div className="w-10 rounded-full">
          <img src={[baseUrl, image].join("")} />
        </div>
      </div>

      <div className="chat-bubble bg-sky-500 text-white">{item.message}</div>
      <div className="chat-footer opacity-50 text-xs">
       {moment.unix(item.creatAt).local().fromNow()}
      </div>
    </div>
  ) : (
    <div className="chat chat-end ">
      <div className="chat-image avatar">
        <div className="w-10 rounded-full">
          <img src={require("../../../assets/img/gear.png")} />
        </div>
      </div>

      <div className="chat-bubble bg-base-1 text-white">{item.message}</div>
      <div className="chat-footer  opacity-50 text-xs">
        {" "}
       {moment.unix(item.creatAt).local().fromNow()}
      </div>
    </div>
  );
}

function Conversation() {
  const dispatch = useDispatch();
  useConversationAdmin();
  const { content, messages, userToSend, loading } = useSelector(
    (state) => state.conversationSlice
  );

  return (
    <div className="col-span-7 flex flex-col pl-8 overflow-hidden  flex-1">
      <div className="w-full flex flex-row h-10 bg-white ">
        <div className="flex w-full">
          <GoBack />
        </div>
        <div className="flex flex-col flex-shrink-0 ">
          <h1 className="text-sm text-end">{userToSend.name}</h1>
          <span className="text-xs text-end lowercase first-letter:capitalize ">
            {userToSend.role}
          </span>
        </div>
      </div>
      <FadeInStagger className="flex scroll-smooth flex-col p-3 h-[calc(100vh-16rem)] overflow-hidden hover:overflow-y-scroll">
        {messages &&
          messages.map((message) => (
            <ChatBubble
              incoming={message.sender !== Roles.admin}
              item={message}
              image={userToSend?.image}
            />
          ))}
      </FadeInStagger>
      <div className="flex w-full flex-none  justify-center items-center sm:px-4">
        <div className="bg-white h-20 grid w-full mb-5 grid-cols-12 gap-x-2 mt-5 rounded-md ring-1 justify-center items-center ring-sky-400">
          <div className="col-span-9 sm:col-span-10  px-3">
            <textarea
              className="textarea w-full h-11 "
              placeholder="type here"
              value={content}
              onChange={(e) => dispatch(setContent(e.target.value))}
            ></textarea>
          </div>
          <div className="col-span-3 sm:col-span-2 flex justify-center">
            <motion.button
              whileHover={{ scale: 1.2 }}
              onHoverStart={(e) => {}}
              onHoverEnd={(e) => {}}
              type="button"
              onClick={() => dispatch(adminSendMessage())}
              className="rounded-full bg-sky-600 p-2 text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
            >
              <PaperAirplaneIcon
                className={`sm:h-5 h-4  w-4 sm:w-5 rotate-[-30deg] ${
                  loading && "animate-ping"
                }`}
                aria-hidden="true"
              />
            </motion.button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Conversation;
