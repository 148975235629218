import React from "react";
import Stats from "./stats";
import TableFinanceHistory from "../../../components/finance/table";

function Finance() {
  return (
    <div>
      <Stats />
      <TableFinanceHistory />
    </div>
  );
}

export default Finance;
