import { PlusIcon } from "@heroicons/react/20/solid";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ListOfAdmin from "./list-of-user";
import { useGetAdmins } from "../../../hook/admin";
import { Button } from "../../../components/utils/Button";
import { openSideUser, setPage } from "../../../redux/admin";

const secondaryNavigation = [
  { name: "Account", href: "#", current: true },
  { name: "Teams", href: "#", current: false },
];

function Profile() {
  useGetAdmins();
  const dispatch = useDispatch();
  const { admin, admins, limit, page } = useSelector(
    (state) => state.adminSlice
  );

  const start = (page - 1) * limit + 1;
  const end = Math.min(page * limit, admins.total);

  return (
    <>
      <div className="divide-y divide-gray-300">
        <div className="grid   grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-800">
              Admin information
            </h2>
            <p className="mt-1 md:text-sm text-xs leading-6 text-gray-600">
              Use a permanent address where you can receive mail.
            </p>
          </div>

          {admin && admin.id && (
            <form className="md:col-span-2">
              <div className="grid grid-cols-1 gap-x-6 md:gap-y-8 gap-y-4  sm:grid-cols-6">
                <div className="col-span-full  flex flex-wrap items-center gap-x-8">
                  <img
                    src={require("../../../assets/img/gear.png")}
                    alt=""
                    className="h-24 w-24 flex-none rounded-lg bg-gray-200 object-cover"
                  />
                  <div>
                    <button
                      type="button"
                      className="rounded-md bg-white px-3 py-2 md:text-sm text-xs font-semibold text-gray-800 shadow-sm hover:bg-gray-100"
                    >
                      Change avatar
                    </button>
                    <p className="mt-2 text-xs leading-5 text-gray-600">
                      JPG, GIF or PNG. 1MB max.
                    </p>
                    <input
                      className="hidden"
                      accept=".jpg,.jpeg,.png,.gif"
                      size={2 * 1024}
                      name=""
                      id=""
                    />
                  </div>
                </div>

                <div className="col-span-full">
                  <label
                    htmlFor="email"
                    className="block md:text-sm text-xs font-medium leading-6 text-gray-800"
                  >
                    Email address / User ID
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      readOnly
                      value={admin.user.email}
                      autoComplete="email"
                      className="block pl-3 w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 md:text-sm text-xs sm:leading-6"
                    />
                  </div>
                </div>
                <div className="col-span-full">
                  <label
                    htmlFor="name"
                    className="block md:text-sm text-xs font-medium leading-6 text-gray-800"
                  >
                    Name
                  </label>
                  <div className="mt-2">
                    <input
                      id="name"
                      name="name"
                      type="text"
                      value={admin.name}
                      readOnly
                      autoComplete="email"
                      className="block pl-3 w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 md:text-sm text-xs sm:leading-6"
                    />
                  </div>
                </div>
                <div className="col-span-full">
                  <label
                    htmlFor="email"
                    className="block md:text-sm text-xs font-medium leading-6 text-gray-800"
                  >
                    Phone
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      readOnly
                      value={admin?.metadata?.phone}
                      autoComplete="email"
                      className="block pl-3 w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 md:text-sm text-xs sm:leading-6"
                    />
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>

        <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-800">
              List of admin users
            </h2>
            <p className="mt-1 md:text-sm text-xs leading-6 text-gray-600">
              See here all the admin user
            </p>
          </div>

          <div className="md:col-span-2">
            <div className="flex justify-end w-full py-4 ">
              <Button onClick={() => dispatch(openSideUser())}>
                New member
              </Button>
            </div>
            <nav
              className="flex  w-full items-center justify-between  bg-white px-4 py-3 sm:px-6"
              aria-label="Pagination"
            >
              <div className="hidden sm:block">
                <p className="text-sm text-gray-700">
                  Showing <span className="font-medium">{start}</span> to{" "}
                  <span className="font-medium">{end}</span> of{" "}
                  <span className="font-medium">{admins.total}</span> results
                </p>
              </div>
              <div className="flex flex-1 justify-between sm:justify-end">
                <button
                  onClick={() => dispatch(setPage(page - 1))}
                  disabled={start === 1}
                  className=" disabled:bg-slate-400/40 disabled:text-white relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                >
                  Previous
                </button>
                <button
                  disabled={end === admins.total}
                  onClick={() => dispatch(setPage(page + 1))}
                  className="disabled:bg-slate-400/40 disabled:text-white relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
                >
                  Next
                </button>
              </div>
            </nav>

            <ListOfAdmin />
          </div>
        </div>
        <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-800">
              Change password
            </h2>
            <p className="mt-1 md:text-sm text-xs leading-6 text-gray-600">
              Update your password associated with your account.
            </p>
          </div>

          <form className="md:col-span-2">
            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
              <div className="col-span-full">
                <label
                  htmlFor="current-password"
                  className="block md:text-sm text-xs font-medium leading-6 text-gray-800"
                >
                  Current password
                </label>
                <div className="mt-2">
                  <input
                    id="current-password"
                    name="current_password"
                    type="password"
                    autoComplete="current-password"
                    className="block pl-3 w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 md:text-sm text-xs sm:leading-6"
                  />
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="new-password"
                  className="block md:text-sm text-xs font-medium leading-6 text-gray-800"
                >
                  New password
                </label>
                <div className="mt-2">
                  <input
                    id="new-password"
                    name="new_password"
                    type="password"
                    autoComplete="new-password"
                    className="block pl-3 w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 md:text-sm text-xs sm:leading-6"
                  />
                </div>
              </div>

              <div className="col-span-full">
                <label
                  htmlFor="confirm-password"
                  className="block md:text-sm text-xs font-medium leading-6 text-gray-800"
                >
                  Confirm password
                </label>
                <div className="mt-2">
                  <input
                    id="confirm-password"
                    name="confirm_password"
                    type="password"
                    autoComplete="new-password"
                    className="block pl-3 w-full rounded-md border-0 py-1.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 md:text-sm text-xs sm:leading-6"
                  />
                </div>
              </div>
            </div>

            <div className="mt-8 flex">
              <button
                type="submit"
                className="rounded-md bg-sky-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-500"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Profile;
