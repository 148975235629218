import { useDispatch, useSelector } from "react-redux";
import { useStripeInvoices } from "../../../hook/stripe";
import {
  setCustomerId,
  setInvoiceId,
  setPage,
  setPrev,
} from "../../../redux/stripe";

const transactions = [
  {
    id: "AAPS0L",
    company: "Chase & Co.",
    share: "CAC",
    commission: "+$4.37",
    price: "$3,509.00",
    quantity: "12.00",
    netAmount: "$4,397.00",
  },
  // More transactions...
];

export default function Invoices() {
  useStripeInvoices();

  const { invoices, page, limit, invoice_id, customer_id } = useSelector(
    (state) => state.stripeSlice
  );
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  const dispatch = useDispatch();
  const start = (page - 1) * limit + 1;

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Invoices
          </h1>
          <p className="mt-2 text-sm text-gray-700">A table of invoices</p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            className="block rounded-md bg-sky-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
          >
            Export
          </button>
        </div>
      </div>

      <hr className="my-5" />
      <div className="mt-8 flow-root  ">
        <div className="grid grid-cols-12 gap-6  ">
          <div className="col-span-4 ">
            <label
              htmlFor="invoice"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Invoice ID
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="invoice"
                id="invoice"
                value={invoice_id}
                onChange={(e) => dispatch(setInvoiceId(e.currentTarget.value))}
                className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:${
                  invoices &&
                  invoices.invoices &&
                  invoices.invoices.data.length > 0
                    ? "ring-sky-600"
                    : "ring-red-600 bg-red-50"
                } sm:text-sm sm:leading-6`}
                placeholder="in_1NvEFDI7eOuKUJKPs9sIIVvk"
              />
            </div>
          </div>
          <div className="col-span-3">
            <label
              htmlFor="customer"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Company / User ID / Email
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="customer"
                id="customer"
                value={customer_id}
                onChange={(e) => dispatch(setCustomerId(e.currentTarget.value))}
                className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:${
                  invoices &&
                  invoices.invoices &&
                  invoices.invoices.data.length > 0
                    ? "ring-sky-600"
                    : "ring-red-600 bg-red-50"
                } sm:text-sm sm:leading-6`}
                placeholder="cus_OdW8jTk6tbVpis"
              />
            </div>
          </div>
        </div>

        <nav
          className="flex  w-full items-center justify-between  bg-white px-4 py-3 sm:px-6"
          aria-label="Pagination"
        >
          <div className="flex flex-1 justify-between sm:justify-end">
            <button
              onClick={() => {
                dispatch(setPrev(true));
                dispatch(setPage(page - 1));
              }}
              disabled={start === 1}
              className="disabled:bg-slate-400/40 disabled:text-white relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
            >
              Previous
            </button>
            <button
              disabled={
                invoices && invoices.invoices && !invoices.invoices.has_more
              }
              onClick={() => {
                dispatch(setPage(page + 1));
                dispatch(setPrev(false));
              }}
              className="disabled:bg-slate-400/40 disabled:text-white relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
            >
              Next
            </button>
          </div>
        </nav>
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Invoice ID
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Company
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Due date
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Amount due
                  </th>
                  <th
                    scope="col"
                    className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    payment method
                  </th>

                  <th
                    scope="col"
                    className="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-0"
                  >
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
         

              <tbody className="divide-y divide-gray-200 bg-white">
                {invoices &&
                  invoices.invoices &&
                  invoices.invoices.data.map((transaction) => (
                    <tr key={transaction.id}>
                      <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">
                        {transaction.id}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                        <div className="flex flex-col">
                          <span> {transaction.customer}</span>
                          <span className="text-xs text-gray-500 font-semibold">
                            {transaction.customer_name}
                          </span>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                        {new Date(
                          transaction.created * 1000
                        ).toLocaleDateString()}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        <div
                          className={classNames(
                            transaction.paid
                              ? "text-green-700 bg-green-50 ring-green-600/20"
                              : "text-gray-600 bg-gray-100 animate-pulse ring-gray-500/10",
                            "rounded-md py-1 px-2 text-center text-xs font-medium ring-1 ring-inset"
                          )}
                        >
                          {transaction.paid ? "Paid" : "Pending"}
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                        {[
                          transaction.currency.toUpperCase(),
                          (transaction.amount_due / 100).toFixed(2),
                        ].join(" ")}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-left text-sm text-gray-500">
                        {transaction.default_payement_method
                          ? transaction.default_payement_method
                          : "----"}
                      </td>

                      <td className="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                        <a
                          href={transaction.hosted_invoice_url}
                          className="text-sky-600 hover:text-sky-900"
                        >
                          Link
                          <span className="sr-only">, {transaction.id}</span>
                        </a>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
